import axios from "axios"
import axiosInstance from "../models/axiosInstance"
import { API } from "./apiUrls"

//Shop Booking
export const createShopBooking = async (parms) => {
  const response = await axiosInstance.post(API.CREATE_SHOP_BOOKING(), parms)
  return response.data
}

//Mobile Booking
export const createMobileBooking = async (parms) => {
  // console.log(parms);
  const response = await axiosInstance.post(API.CREATE_MOBILE_BOOKING(), parms)
  return response.data
}

//upload Image
export const uploadImage = async (parms) => {
  const data = new FormData()
  for (let i = 0; i < parms.length; i++) {
    data.append("images", parms[i])
  }

  const response = await axiosInstance.post(API.UPLOAD_IMAGE(), data)
  return response.data
}

//calculatePricing
export const calculatePricing = async (request) => {
  const response = await axiosInstance.post(
    API.CALCULATE_SHOP_PRICING(),
    request
  )
  return response.data
}

//get slots
export const getBookingSlots = async (request) => {
  const response = await axiosInstance.post(API.GET_BOOKING_SLOTS(), request)
  return response.data
}

// get discount
export const getDiscount = async (request) => {
  const response = await axiosInstance.post(API.GET_DISCOUNT(), request)
  return response.data
}

// get discount
export const getConnectServer = async () => {
  const response = await axios.get("https://api.boostceramic.ca/")
  return response.data
}
