import { combineReducers } from "@reduxjs/toolkit"
import bookingReducer from "./bookingReducer"
import toastifyReducer from "./toastifyReducer"
import multipleBookingReducer from "./multipleBookingReducer"

const rootReducer = combineReducers({
  bookingReducer,
  toastifyReducer,
  multipleBookingReducer
})
export default rootReducer
