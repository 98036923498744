import moment from "moment"
import React from "react"

const Footer = () => {
  return (
    <>
      <div className="text-xs py-2 px-5 border-t-2">
        <div className="text- center flex justify-between items-center">
          <div className="flex flex-wrap space-x-2">
            <span>&copy; {moment().format("YYYY")}. Copyright</span>
            <a
              className="underline font-medium capitalize underline-offset-2"
              href="https://boostmobiledetailing.ca/"
              target="_blank"
              rel="noreferrer"
            >
              boost mobile detailing
            </a>
          </div>
          {/* <div className="flex flex-wrap justify-end space-x-2">
            <span>Powered By </span>
            <a
              className="underline font-medium underline-offset-2"
              href="https://linktr.ee/r.media"
              target="_blank"
              rel="noreferrer"
            >
              R Enterprise
            </a>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default Footer
