export const REDUX_STORE_DEFAULTS = {
  UPDATE_BOOKING_STEPS: () => `UPDATE_BOOKING_STEPS`,
  UPDATE_BOOKING_LOCATION: () => `UPDATE_BOOKING_LOCATION`,
  UPDATE_BOOKING_STAFF: () => `UPDATE_BOOKING_STAFF`,
  UPDATE_BOOKING_SERVICE_CATEGORY: () => `UPDATE_BOOKING_SERVICE_CATEGORY`,
  UPDATE_BOOKING_SERVICE: () => `UPDATE_BOOKING_SERVICE`,
  UPDATE_BOOKING_ADD_ON_SERVICE: () => `UPDATE_BOOKING_ADD_ON_SERVICE`,
  UPDATE_BOOKING_TIME_SLOT: () => `UPDATE_BOOKING_TIME_SLOT`,
  UPDATE_USER_INFO: () => `UPDATE_USER_INFO`,
  UPDATE_OTHER_USER_INFO: () => `UPDATE_OTHER_USER_INFO`,
  UPDATE_OTHER_VEHICLE_INFO: () => `UPDATE_OTHER_VEHICLE_INFO`,
  UPDATE_BOOKING_CONFIRMATION: () => `UPDATE_BOOKING_CONFIRMATION`,
  UPDATE_MULTIPLE_BOOKING: () => `UPDATE_MULTIPLE_BOOKING`,
  UPDATE_MULTIPLE_BOOKING_DATA: () => `UPDATE_MULTIPLE_BOOKING_DATA`,
  UPDATE_VEHICLE_DATA: () => `UPDATE_VEHICLE_DATA`,
  UPDATE_SERVICE_DATA: () => `UPDATE_SERVICE_DATA`,
  UPDATE_ADDON_SERVICE_DATA : () => `UPDATE_ADDON_SERVICE_DATA`,
  CLEAR_REDUX_DATA: () => `CLEAR_REDUX_DATA`,
  UPDATE_DATA: () => `UPDATE_DATA`,
  UPDATE_DISCOUNT: () => `UPDATE_DISCOUNT`,
  UPDATE_SUBTOTAL: () => `UPDATE_SUBTOTAL`,
}

export const REDUX_TOASTIFY_DEFAULTS = {
  UPDATE_TOASTIFY_SUCCESS: () => `SUCCESS`,
  UPDATE_TOASTIFY_WARN: () => `WARN`,
  UPDATE_TOASTIFY_INFO: () => `INFO`,
  UPDATE_TOASTIFY_ERROR: () => `ERROR`,
  UPDATE_TOASTIFY_DEFAULT: () => `DEFAULT`,
}
