import { REDUX_STORE_DEFAULTS } from "../config"

const initialState = {
  allBookingData: [],
}

const multipleBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_STORE_DEFAULTS.UPDATE_VEHICLE_DATA():
      return {
        ...state,
        allBookingData: updateDataArray(
          state.allBookingData,
          action.payload.newVehicle,
          "vehicle",
          action.payload.multipleBookingStatus
        ),
      }
    case REDUX_STORE_DEFAULTS.UPDATE_SERVICE_DATA():
      return {
        ...state,
        allBookingData: updateDataArray(
          state.allBookingData,
          action.payload.newService,
          "service",
          action.payload.multipleBookingStatus
        ),
      }
    case REDUX_STORE_DEFAULTS.UPDATE_ADDON_SERVICE_DATA():
      return {
        ...state,
        allBookingData: updateDataArray(
          state.allBookingData,
          action.payload.newAddOnService,
          "addOnService",
          action.payload.multipleBookingStatus
        ),
      }
    case REDUX_STORE_DEFAULTS.UPDATE_DATA():
      return {
        ...state,
        allBookingData: updateDataArray(
          state.allBookingData,
          action.payload.data,
          action.payload.stepNo,
          action.payload.multipleBookingStatus
        ),
      }
    case REDUX_STORE_DEFAULTS.UPDATE_SUBTOTAL():
      return {
        ...state,
        allBookingData: updateServicePricesInDataArray(
          state.allBookingData,
          action.payload.otherVehicleInfo
        ),
      }
    default:
      return state
  }
}

const updateDataArray = (dataArray, newData, stepNo, multipleBookingStatus) => {
  const latestIndex = dataArray.length - 1
  const latestData = dataArray[latestIndex]

  if (stepNo === 2) {
    if (
      dataArray.length === 0 ||
      (hasServiceAndAddOnService(latestData) && multipleBookingStatus)
    ) {
      // Add a new vehicle entry if the array is empty or the latest entry has both service and addOnService
      return [...dataArray, { vehicle: newData }]
    } else {
      // Update the latest vehicle entry
      return dataArray.map((data, i) => {
        if (i === latestIndex) {
          return {
            ...data,
            vehicle: newData,
            service: {},
            addOnService: [],
            subTotal: 0,
            totalAddOnPrice: 0,
          }
        }
        return data
      })
    }
  }

  if (stepNo === 3 && latestIndex >= 0 && dataArray[latestIndex].vehicle) {
    // Add or update the service data in the latest vehicle entry
    return dataArray.map((data, i) => {
      if (i === latestIndex) {
        return {
          ...data,
          service: newData,
          addOnService: [],
          subTotal: newData.servicePrice,
          totalAddOnPrice: 0,
        }
      }
      return data
    })
  }

  if (stepNo === 4 && latestIndex >= 0 && dataArray[latestIndex].vehicle) {
    // Update the add-on service data in the latest vehicle entry
    return dataArray.map((data, i) => {
      // let bookingAddOnPrice = calculateAddOnPrice(data)
      // console.log("redux price", bookingAddOnPrice)
      if (i === latestIndex) {
        // Check if addOnService already exists, if yes, merge with existing data
        const updatedAddOnService = data.addOnService ? newData : newData
        let bookingAddOnPrice = calculateAddOnPrice(updatedAddOnService)
        // console.log("redux price", bookingAddOnPrice)
        return {
          ...data,
          addOnService: updatedAddOnService,
          totalAddOnPrice: bookingAddOnPrice,
          subTotal: bookingAddOnPrice + data.service.servicePrice,
        }
      }
      return data
    })
  }

  if (stepNo === 1) {
    return []
  }

  return dataArray
}

const hasServiceAndAddOnService = (data) => {
  return data.service && data.addOnService
}

const calculateAddOnPrice = (addOnServices) => {
  if (!Array.isArray(addOnServices)) return 0
  return addOnServices.reduce((total, service) => total + service.addOnPrice, 0)
}
// const calculateAddOnPrice = (addOnServices, vid, otherVehicleInfo, stepNo) => {
//   if (!Array.isArray(addOnServices)) return 0
//   return addOnServices.reduce((total, service) => {
//     let addOnPrice = service.addOnPrice
//     // Apply custom logic based on vid and otherVehicleInfo
//     if (vid === 5 && otherVehicleInfo.rvLength > 1 && stepNo === 6) {
//       addOnPrice = otherVehicleInfo.rvLength * addOnPrice
//     } else if (vid === 7 && otherVehicleInfo.busSeats > 1 && stepNo === 6) {
//       addOnPrice = otherVehicleInfo.busSeats * addOnPrice
//     } else if (vid === 8 && otherVehicleInfo.boatLength > 1 && stepNo === 6) {
//       addOnPrice = otherVehicleInfo.boatLength * addOnPrice
//     } else if (vid === 10 && otherVehicleInfo.houseSqFeet > 1 && stepNo === 6) {
//       addOnPrice = otherVehicleInfo.houseSqFeet * addOnPrice
//     }
//     return total + addOnPrice
//   }, 0)
// }

const handleServicePrice = (servicePrice, vid, otherVehicleInfo, stepNo) => {
  if (vid === 5 && otherVehicleInfo.rvLength > 1 && stepNo === 6) {
    return otherVehicleInfo.rvLength * servicePrice
  } else if (vid === 7 && otherVehicleInfo.busSeats > 1 && stepNo === 6) {
    return otherVehicleInfo.busSeats * servicePrice
  } else if (vid === 8 && otherVehicleInfo.boatLength > 1 && stepNo === 6) {
    return otherVehicleInfo.boatLength * servicePrice
  } else if (vid === 10 && otherVehicleInfo.houseSqFeet > 1 && stepNo === 6) {
    return otherVehicleInfo.houseSqFeet * servicePrice
  }
  return servicePrice
}

const getTotalLength = (vid, otherVehicleInfo) => {
  if (vid === 5) {
    return otherVehicleInfo.rvLength
  } else if (vid === 7) {
    return otherVehicleInfo.busSeats
  } else if (vid === 8) {
    return otherVehicleInfo.boatLength
  } else if (vid === 10) {
    return otherVehicleInfo.houseSqFeet
  }
  return 0
}

const updateServicePricesInDataArray = (dataArray, otherVehicleInfo) => {
  return dataArray.map((data) => {
    if (data.vehicle && data.service) {
      const updatedServicePrice = handleServicePrice(
        data.service.servicePrice, //TODO service price + addon Total Price
        data.vehicle.id,
        otherVehicleInfo,
        6
      )
      const totalLength = getTotalLength(data.vehicle.id, otherVehicleInfo)
      const pricingType = totalLength > 0 ? "rate" : "fixed"
      return {
        ...data,
        subTotal:
          updatedServicePrice +
          calculateAddOnPrice(
            data.addOnService,
            data.vehicle.id,
            otherVehicleInfo,
            6
          ),
        images: otherVehicleInfo?.picture ?? [],
        totalLength: totalLength,
        pricingType: pricingType,
      }
    }
    return data
  })
}

export default multipleBookingReducer
