// src/context/ImageContext.js

import React, { createContext, useContext, useEffect, useState } from "react";

const ImageContext = createContext();

const imageUrls = {
  carSedan:
    "https://res.cloudinary.com/dsprifizw/image/upload/v1719277375/car_sedan-removebg-preview_p9vpjb.webP",
  suv5SeaterWhite:
    "https://res.cloudinary.com/dsprifizw/image/upload/v1719277699/suv_5_seater_white-removebg-preview_ilib0i.webP",
  pickupTruck:
    "https://res.cloudinary.com/dsprifizw/image/upload/v1719277817/Puckup_Truck-removebg-preview_vlwsyj.webP",
  suv7Seater:
    "https://res.cloudinary.com/dsprifizw/image/upload/v1719277862/suv_7_seater-removebg-preview_moe1tc.webP",
  commercialTruck:
    "https://res.cloudinary.com/dsprifizw/image/upload/v1719277924/Commercial_Truck-removebg-preview_ulo3fz.webP",
  rvMotorhomes:
    "https://res.cloudinary.com/dsprifizw/image/upload/v1719277995/RV_motorhomes-removebg-preview_y7xulx.webP",
  whiteFireTruck:
    "https://res.cloudinary.com/dsprifizw/image/upload/v1719278070/White_Fire_Truck-removebg-preview_hausrv.webP",
  bus: "https://res.cloudinary.com/dsprifizw/image/upload/v1719278127/bus-removebg-preview-1_woaqq8.webP",
  yacht:
    "https://res.cloudinary.com/dsprifizw/image/upload/v1719278179/Yacht-removebg-preview_jbjuml.webP",
  whiteHelicopter:
    "https://res.cloudinary.com/dsprifizw/image/upload/v1719278231/White_Helicopter1-removebg_u3ffwu.webP",
  whiteSmallHouse:
    "https://res.cloudinary.com/dsprifizw/image/upload/v1719278286/White_Small_House-removebg-preview_atpbam.webP",
  mobileImgBlack:
    "https://res.cloudinary.com/dsprifizw/image/upload/v1719278984/mobileimgBlack_juzhjk.webP",
  shopImg:
    "https://res.cloudinary.com/dsprifizw/image/upload/v1719279080/shopImg_qpzhph.webP",
};

export const ImageProvider = ({ children }) => {
  const [images, setImages] = useState({});

  useEffect(() => {
    const fetchImages = async () => {
      const imagePromises = Object.keys(imageUrls).map(async (key) => {
        const response = await fetch(imageUrls[key]);
        const blob = await response.blob();
        return { key, url: URL.createObjectURL(blob) };
      });

      const results = await Promise.all(imagePromises);
      const imagesObject = results.reduce((acc, { key, url }) => {
        acc[key] = url;
        return acc;
      }, {});

      setImages(imagesObject);
    };

    fetchImages();
  }, []);

  return (
    <ImageContext.Provider value={images}>{children}</ImageContext.Provider>
  );
};

export const useImages = () => useContext(ImageContext);
