import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { bookingAction } from "../../store/actions/bookingAction"
import { openToastify } from "../../store/actions/toastifyAction"
import {
  REDUX_STORE_DEFAULTS,
  REDUX_TOASTIFY_DEFAULTS,
} from "../../store/config"
import { AppointmentData } from "../../config/bookingDataConfig"
import { updateData } from "../../store/actions/multipleBookingAction"

const BookingStepBtnSection = () => {
  const dispatch = useDispatch()
  let {
    bookingStepsLabel,
    stepNo,
    location,
    staffCategory,
    service,
    bookingSlot,
    serviceCategory,
    userInfo,
    otherUserInfo,
    multipleBookingStatus,
  } = useSelector((state) => state.bookingReducer)

  const completeBooking = () => {
    if (otherUserInfo.conset === "I agree") {
      dispatch(
        bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_CONFIRMATION(), true)
      )
    } else {
      dispatch(
        openToastify(
          "Fill All Detials & Options",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
        )
      )
    }
  }

  // Handle booking steps next or back
  const bookingStepHander = (id) => {
    if (id === "NEXT") {
      if (!location) {
        dispatch(
          openToastify(
            "Select Location",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
      } else if (stepNo === 2 && !staffCategory.staffDisplayName) {
        dispatch(
          openToastify(
            "Select Vehicle",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
      } else if (
        stepNo === 3 &&
        !serviceCategory.categoryName &&
        AppointmentData[location?.id]?.staff[staffCategory?.id]?.servicesList
          .length
      ) {
        dispatch(
          openToastify(
            "Select Service Category",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
      } else if (
        stepNo === 4 &&
        !service.serviceName &&
        AppointmentData[location?.id]?.staff[staffCategory?.id]?.servicesList
          .length
      ) {
        dispatch(
          openToastify(
            "Select Service",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
      } else if (stepNo === 6 && !bookingSlot?.createdAt) {
        dispatch(
          openToastify(
            "Select Booking Slot",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
      } else if (stepNo === 7 && otherUserInfo.conset !== "I agree") {
        //information step
        dispatch(
          openToastify(
            "Fill All Details",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
        // return
      } else {
        //checking services and addon servces list
        if (
          AppointmentData[location?.id]?.staff[staffCategory?.id]?.servicesList
            .length === 0 &&
          stepNo < 4
        ) {
          stepNo = stepNo + 4
        } else if (
          AppointmentData[location?.id]?.staff[staffCategory?.id]?.servicesList[
            service?.id
          ]?.services[service?.serviceId]?.addonService.length === 0 &&
          stepNo === 4
        ) {
          stepNo = stepNo + 2
        } else {
          stepNo = stepNo + 1
        }
        dispatch(
          bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STEPS(), stepNo)
        )
      }
    } else if (id === "BACK") {
      //GO BACK

      //on going back if redux is empty, add other if needed
      // if (!service.serviceName && stepNo > 3 && stepNo < 5) {
      //   dispatch(bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STEPS(), 3))
      // } else {
      //   stepNo = stepNo - 1
      //   dispatch(
      //     bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STEPS(), stepNo)
      //   )
      // }

      if (
        AppointmentData[location?.id]?.staff[staffCategory?.id]?.servicesList
          .length === 0 &&
        stepNo === 5
      ) {
        stepNo = stepNo - 3
      } else if (
        AppointmentData[location?.id]?.staff[staffCategory?.id]?.servicesList[
          service?.id
        ]?.services[service?.serviceId]?.addonService.length === 0 &&
        stepNo === 6
      ) {
        stepNo = stepNo - 2
      } else if (multipleBookingStatus && stepNo >= 2 && stepNo <= 6) {
        dispatch(
          openToastify(
            "Cannot Add More Than 2 Booking",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
        return
      } else if (multipleBookingStatus && stepNo === 6) {
        return
      } else {
        stepNo = stepNo - 1
      }
      dispatch(
        bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STEPS(), stepNo)
      )
    } else {
      completeBooking()
    }
  }

  const ResetBooking = () => {
    dispatch(
      dispatch(bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STAFF(), {}))
    )
    dispatch(bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STEPS(), 1))
    dispatch(
      bookingAction(REDUX_STORE_DEFAULTS.UPDATE_MULTIPLE_BOOKING(), false)
    )
    dispatch(updateData([], 1))
  }  
  return (
    <div
      className={` py-5 px-1 flex flex-wrap flex-c ol-reverse sm:flex -row sm:space-y- 6 font-medium ${
        stepNo > 1 ? "justify-between" : "justify-end"
      } `}
    >
      {/* goto previous step */}
      {stepNo > 1 && (
        <button
          id="BACK"
          className="uppercase text-black border-2 border-black bg-white w-[10rem] h-10 rounded-lg hover:shadow-lg hover:shadow-yellow-light_medium"
          onClick={(e) => {
            bookingStepHander(e.currentTarget.id)
          }}
        >
          Back
        </button>
      )}

      <div className="flex flex-col-reverse md:flex-row justify-between lg:w-[65%]">
        {multipleBookingStatus && stepNo > 2 ? (
          <button
            id="RESET"
            className="uppercase md:mt-0 mt-6 bg-yellow-medium_dark md:w-[20rem] h-10 rounded-lg hover:shadow-lg hover:shadow-white"
            onClick={() => {
              ResetBooking()
            }}
          >
            Reset Booking
          </button>
        ) : (
          <></>
        )}

        <div className="w-full flex justify-end">
          {/* goto next step */}
          {stepNo === bookingStepsLabel.length ? (
            <button
              id="CONFIRM"
              className="uppercase bg-yellow-medium_dark w-[12rem] h-10 rounded-lg hover:shadow-lg hover:shadow-white"
              onClick={(e) => {
                bookingStepHander(e.currentTarget.id)
              }}
            >
              Confirm Booking
            </button>
          ) : (
            <button
              id="NEXT"
              className="uppercase bg-yellow-medium_dark w-[10rem] h-10 rounded-lg hover:shadow-lg hover:shadow-white"
              onClick={(e) => {
                bookingStepHander(e.currentTarget.id)
              }}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default BookingStepBtnSection
