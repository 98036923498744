import React, { useEffect, useState } from "react"
import SelectVehicle from "../UI/BookingForm/SelectStaff"
import SelectLocation from "../UI/BookingForm/SelectLocation"
import { useDispatch, useSelector } from "react-redux"
import { bookingAction } from "../store/actions/bookingAction"
import { openToastify } from "../store/actions/toastifyAction"
import { REDUX_STORE_DEFAULTS, REDUX_TOASTIFY_DEFAULTS } from "../store/config"
import SelectService from "../UI/BookingForm/SelectService"
import SelectExtraService from "../UI/BookingForm/SelectExtraService"
import { BookingCalender } from "../UI/Calendar/BookingCalender"
import InformationForm from "../UI/BookingForm/InformationForm"
import SummarySection from "./sections/SummarySection"
import { AppointmentData } from "../../components/config/bookingDataConfig"
import BookingStepBtnSection from "./sections/BookingStepBtnSection"
import Confirmation from "../UI/BookingForm/Confirmation"
import SelectServiceCategory from "../UI/BookingForm/SelectServiceCategory"
import { getConnectServer } from "../services/api/apiModule"

const Booking = () => {
  const dispatch = useDispatch()
  let {
    bookingStepsLabel,
    stepNo,
    location,
    staffCategory,
    service,
    bookingSlot,
    confirmBooking,
  } = useSelector((state) => state.bookingReducer)

  // On location change the page go to next step
  useEffect(() => {
    // bookingStepHander("NEXT")
    // console.log("here", location, bookingStepsLabel)
    //  console.log(
    //     "Checking steps",
    //     AppointmentData[location?.id]?.staff[staffCategory?.id]?.servicesList[
    //       service?.id
    //     ]?.services[service?.serviceId]?.addonService.length
    //   )
    window.scrollTo(0, 0)
  }, [stepNo])

  const booking = () => {
    switch (stepNo) {
      case 1:
        return <SelectLocation />

      case 2:
        return <SelectVehicle />

      case 3:
        return <SelectServiceCategory />

      case 4:
        return <SelectService />

      case 5:
        return <SelectExtraService />

      case 6:
        return <BookingCalender />

      case 7:
        return <InformationForm />

      // case 7:
      //   return <Confirmation />

      default:
        break
    }
  }

  const hitServerApi = async () => {
    try {
      const response = await getConnectServer()
      console.log(response.msg)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (stepNo === 6) {
      hitServerApi()
    }
  }, [stepNo])

  return (
    <>
      <div className="h-full w-full relative overflow-y-scroll allbooking_scroll overflow-x-hidden flex flex-col lg:flex-row items- center justify-between md:p-5">
        {/* <div
          className="w-[30rem] h-[30rem] rounded-full opacity-50 absolute -right-40"
          style={{
            background:
              "radial-gradient(#e9c46b -70%, #e9c46b -20%, transparent 70%)",
          }}
        ></div>
        <div
          className="w-[30rem] h-[30rem] rounded-full opacity-50 absolute -left-40 bottom-0"
          style={{
            background:
              "radial-gradient(#e9c46b -70%, #e9c46b -20%, transparent 70%)",
          }}
        ></div> */}

        {/* booking steps */}
        <div className="font-medium sm:w- [59%] flex-1 pt-5 space-y-5 divide-y-2 divide-black sm:mx-2 flex flex-col justify-between">
          {/* Showing Steps numbers */}

          <div className="flex items-center justify-between sm:w- full">
            {bookingStepsLabel.map((step, index) => (
              <div key={index} className="flex items-center flex-col">
                <div
                  className={`w-6 h-6 flex items-center justify-center rounded-full border-2 border-gray-300 ${
                    index < stepNo
                      ? "bg-yellow-medium_dark"
                      : "bg-gray-300 text-black"
                  }`}
                >
                  {index + 1}
                </div>
                <div
                  className={`hidden lg:flex text-sm ${
                    index < stepNo - 1 ? "text-yellow-medium_dark" : ""
                  }`}
                >
                  {step.label}
                </div>
              </div>
            ))}
          </div>

          {/* Next step or back */}
          <BookingStepBtnSection />

          {/* Steps */}
          <div className="sm:w- full sm:h-full lg:overflow-y-scroll booking_scroll p-3">
            {booking()}
          </div>

          {/* Next step or back */}
          <BookingStepBtnSection />
        </div>

        {/* booking summary */}
        <SummarySection />
      </div>
    </>
  )
}

export default Booking
