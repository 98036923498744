import React, { useEffect, useRef, useState } from "react"
import {
  APIProvider,
  ControlPosition,
  MapControl,
  AdvancedMarker,
  Map,
  useMap,
  useMapsLibrary,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps"
import { useDispatch, useSelector } from "react-redux"
import { bookingAction } from "../../store/actions/bookingAction"
import { REDUX_STORE_DEFAULTS } from "../../store/config"

const API_KEY = "AIzaSyC9U_W6NDorvK8-ani4lB0jtN21lYLi8d4"

const GoogleMap = () => {
  const [selectedPlace, setSelectedPlace] = useState(null)
  const [markerRef, marker] = useAdvancedMarkerRef()
  const dispatch = useDispatch()
  let {
    bookingStepsLabel,
    stepNo,
    location,
    staffCategory,
    service,
    addOnService,
    bookingSlot,
    userInfo,
    otherUserInfo,
    otherVehicleInfo,
    confirmBooking,
  } = useSelector((state) => state.bookingReducer)

  const MapHandler = ({ place, marker }) => {
    const map = useMap()

    useEffect(() => {
      if (!map || !place || !marker) return

      if (place.geometry?.viewport) {
        map.fitBounds(place.geometry?.viewport)
      }

      marker.position = place.geometry?.location
    }, [map, place, marker])
    return null
  }

  const PlaceAutocomplete = ({ onPlaceSelect }) => {
    const [placeAutocomplete, setPlaceAutocomplete] = useState(null)
    const inputRef = useRef(null)
    const places = useMapsLibrary("places")

    useEffect(() => {
      if (!places || !inputRef.current) return

      const options = {
        fields: ["geometry", "name", "formatted_address"],
      }

      setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options))
    }, [places])
    useEffect(() => {
      if (!placeAutocomplete) return

      placeAutocomplete.addListener("place_changed", () => {
        onPlaceSelect(placeAutocomplete.getPlace())
      })
    }, [onPlaceSelect, placeAutocomplete])
    return (
      <div className="autocomplete-container flex items-center text-black ">
        <input
          ref={inputRef}
          className="h-10 w-[100%] rounded-md px-2 py-2 border"
          value={selectedPlace?.formatted_address}
          required
          placeholder="Enter Your Location"
          onChange={() => {}}
        />
        {selectedPlace ? (
          <span
            className="-ml-9 cursor-pointer px-1 py-1"
            title="clear"
            onClick={() => {
              setSelectedPlace(null)
            }}
          >
            <svg
              className="size-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </span>
        ) : (
          <></>
        )}
      </div>
    )
  }

  useEffect(() => {
    dispatch(
      bookingAction(REDUX_STORE_DEFAULTS.UPDATE_USER_INFO(), {
        ...userInfo,
        address: selectedPlace?.formatted_address,
      })
    )
    console.log(selectedPlace?.formatted_address)
    if (selectedPlace) {
    }
  }, [selectedPlace])

  return (
    <APIProvider
      apiKey={API_KEY}
      solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
    >
      <div className="autocomplete-control">
        <PlaceAutocomplete onPlaceSelect={setSelectedPlace} />
      </div>
    </APIProvider>
  )
}

// const root = createRoot(document.getElementById("app"));

// root.render(<GoogleMap />);

export default GoogleMap
