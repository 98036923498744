import React, { useEffect, useState } from "react"
import logo from "../../asserts/boost-detailing-logo.png"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import { REDUX_STORE_DEFAULTS } from "../../store/config"
import { bookingAction } from "../../store/actions/bookingAction"
import { AppointmentData } from "../../config/bookingDataConfig"
import {
  updateBookingData,
  updateData,
  updateServiceData,
} from "../../store/actions/multipleBookingAction"

const SelectService = () => {
  const dispatch = useDispatch()
  const [dropDownKey, setDropDownKey] = useState()
  // const [selectService, setSelectService] = useState()
  const [selectService, setSelectService] = useState({
    categoryId: "",
    serviceId: "",
  })
  const [allServicesList, setAllServicesList] = useState([])
  const [isReadMore, setIsReadMore] = useState([])
  let {
    bookingStepsLabel,
    stepNo,
    location,
    staffCategory,
    service,
    allBookingData,
    multipleBookingStatus,
    serviceCategory,
  } = useSelector((state) => state.bookingReducer)

  useEffect(() => {
    if (Object.keys(staffCategory).length) {
      setAllServicesList(
        AppointmentData[location.id].staff[staffCategory.id].servicesList[
          serviceCategory.id
        ].services
      )
    }
  }, [])

  const handleDropDown = (e) => {
    if (e === dropDownKey) {
      setDropDownKey()
    } else {
      setDropDownKey(e)
    }
    // setDropDown(!dropDown)
    // console.log(dropDownKey)
  }

  const handleService = (serviceProp) => {
    const serviceData = {
      id: serviceCategory.id,
      categoryName: serviceCategory.categoryName,
      categoryType: serviceCategory.categoryType,
      description: serviceCategory.description,
      servCategoryImgUrl: serviceCategory.servCategoryImgUrl,
      serviceId: serviceProp.id,
      serviceName: serviceProp.serviceDisplayName,
      serviceShortDiscription: serviceProp.serviceShortDiscription,
      servicePrice: serviceProp.price,
    }

    setSelectService((categoryId) => ({
      ...categoryId,
      categoryId: serviceCategory.id,
    }))
    setSelectService((serviceId) => ({
      ...serviceId,
      serviceId: serviceProp.id,
    }))
    // setSelectService(serviceProp.id)
    //// console.log("sServices", serviceCategory, serviceProp.id, selectService)
    //// console.log("s", selectService)

    if (
      serviceProp.id === selectService.serviceId &&
      serviceCategory.id === selectService.categoryId
    ) {
      // console.log(true)
      dispatch(
        dispatch(
          bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_SERVICE(), {})
        )
      )
      setSelectService(
        (categoryId, serviceId) => (
          {
            ...categoryId,
            categoryId: "",
          },
          {
            ...serviceId,
            serviceId: "",
          }
        )
      )
      dispatch(updateData({}, 3))
    } else {
      dispatch(
        dispatch(
          bookingAction(
            REDUX_STORE_DEFAULTS.UPDATE_BOOKING_SERVICE(),
            serviceData
          )
        )
      )

      dispatch(updateData(serviceData, 3))

      // setTimeout(() => {
      //   dispatch(
      //     bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STEPS(), stepNo + 1)
      //   )
      // }, 100)
    }
  }

  const handleReadMore = (id) => {
    setIsReadMore((prevReadMoreIds) => {
      if (prevReadMoreIds.includes(id)) {
        // Remove the ID if it is already in the array
        return prevReadMoreIds.filter((readMoreId) => readMoreId !== id)
      } else {
        // Add the ID to the array if it is not already in it
        return [...prevReadMoreIds, id]
      }
    })
  }

  useEffect(() => {
    handleReadMore(isReadMore)
  }, [dropDownKey])

  return (
    <>
      <h1>Select Service Type</h1>
      {allServicesList &&
        allServicesList.map((value, key) => {
          return (
            <>
              <div
                key={key + "services"}
                className={` relative border-2 p-2 cursor-pointer rounded mt-2 overflow-hidden
                    ${
                      service.serviceId === value.id
                        ? "shadow-sm shadow-white bg-yellow-medium_dark bg-opacity- 35"
                        : "border-yellow-light"
                    } 
                     `}
              >
                <div
                  className="flex items- center justify-between pr-3"
                  onClick={() => {
                    handleService(value)
                  }}
                >
                  <div className="flex w-full pb-5">
                    {value.imageUrl && (
                      <div className="p-1 w-[7rem] h-[5rem]">
                        <img
                          src={value.imageUrl}
                          alt=""
                          className="w-[7rem] h-[5rem] object-contain"
                        />
                      </div>
                    )}

                    {value.ribbon && (
                      <div className="absolute w-[10rem] text-center top-[1rem] -right-[3.4rem] text-white bg-yellow-light rotate-45 capitalize shadow-md shadow-white">
                        <span>{value.ribbon}</span>
                      </div>
                    )}

                    <div className="flex flex-col ml-2 md:text-lg w-full">
                      <div className="font-extrabold flex w-[90%] justify-between items-center">
                        <h1 className="w-40 md:w-auto">
                          {value.serviceDisplayName}
                        </h1>

                        <div className="flex flex-col items-end pt-2">
                          {value.price > 0 && (
                            <span className="text-lg">
                              ${value.price}
                              {staffCategory.id === 5 ||
                              staffCategory.id === 8 ? (
                                <span>/Per Feet</span>
                              ) : (
                                <></>
                              )}
                              {staffCategory.id === 7 ? (
                                <span>/Per Seat</span>
                              ) : (
                                <></>
                              )}
                              {staffCategory.id === 10 ? (
                                <span>/Per Sq.Feet</span>
                              ) : (
                                <></>
                              )}
                            </span>
                          )}
                          {value.oldPrice > 0 && (
                            <del className="font-normal text-red-600">
                              ${value.oldPrice}
                            </del>
                          )}
                        </div>
                      </div>
                      <p
                        className={` font-normal text-xs md:text-sm ${
                          isReadMore.includes(value.id) ? "pb-3" : "h-16"
                        } overflow-y-hidden `}
                      >
                        {value.serviceDiscription}
                      </p>
                    </div>
                  </div>

                  {/* <div className="h-32 md:h-22 md:mr -6 flex pt-14 flex-col items-end">
                    {value.price > 0 && (
                      <span className="text-lg">${value.price}</span>
                    )}
                    {value.oldPrice > 0 && (
                      <del className="font-normal text-red-600">
                        ${value.oldPrice}
                      </del>
                    )}
                  </div> */}
                </div>

                {value.serviceShortDiscription !== "" && (
                  <span
                    onClick={() => {
                      handleReadMore(value.id)
                    }}
                    className="text-sm font-semibold underline pl-2 h-5 absolute bottom-1"
                  >
                    {isReadMore.includes(value.id) ? "Show Less" : "Read More"}
                  </span>
                )}
              </div>
            </>
          )
        })}
    </>
  )
}

export default SelectService
