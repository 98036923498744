import { REDUX_STORE_DEFAULTS } from "../config"

export const updateVehicleData = (newVehicle, multipleBookingStatus) => ({
  type: REDUX_STORE_DEFAULTS.UPDATE_VEHICLE_DATA(),
  payload: { newVehicle, multipleBookingStatus },
})

export const updateServiceData = (newService, multipleBookingStatus) => ({
  type: REDUX_STORE_DEFAULTS.UPDATE_SERVICE_DATA(),
  payload: { newService, multipleBookingStatus },
})

export const updateAddOnServiceData = (
  newAddOnService,
  multipleBookingStatus
) => ({
  type: REDUX_STORE_DEFAULTS.UPDATE_ADDON_SERVICE_DATA(),
  payload: { newAddOnService, multipleBookingStatus },
})

export const updateData = (data, stepNo, multipleBookingStatus) => ({
  type: REDUX_STORE_DEFAULTS.UPDATE_DATA(),
  payload: { data, stepNo, multipleBookingStatus },
})

export const updatePriceData = (otherVehicleInfo) => ({
  type: REDUX_STORE_DEFAULTS.UPDATE_SUBTOTAL(),
  payload: { otherVehicleInfo },
})
