import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { REDUX_STORE_DEFAULTS } from "../../store/config"
import { bookingAction } from "../../store/actions/bookingAction"
import { AppointmentData } from "../../config/bookingDataConfig"
import {
  updateBookingData,
  updateData,
  updateVehicleData,
} from "../../store/actions/multipleBookingAction"

const SelectStaff = () => {
  const [isStaffselected, setIsStaffSelected] = useState()
  const dispatch = useDispatch()
  let {
    bookingStepsLabel,
    stepNo,
    location,
    staffCategory,
    service,
    multipleBookingStatus,
  } = useSelector((state) => state.bookingReducer)

  let { allBookingData } = useSelector((state) => state.multipleBookingReducer)
  const [allVehicleList, setAllVehicleList] = useState()

  useEffect(() => {
    if (location) {
      setAllVehicleList(AppointmentData[location.id].staff)
    }
  }, [])

  const submitHandler = (propValue) => {
    setIsStaffSelected(propValue.id)
    // console.log("Staff", propValue, "and", staffCategory);

    const staffData = {
      id: propValue.id,
      staffDisplayName: propValue.staffDisplayName,
      staffImgUrl: propValue.staffImgUrl,
      description: propValue.description,
      staffDiscount: propValue.staffDiscount,
      availablelocation: propValue.availablelocation,
      type: propValue.type,
    }
    if (staffCategory.id === propValue.id) {
      dispatch(
        dispatch(bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STAFF(), {}))
      )
      dispatch(updateData({}, 2, multipleBookingStatus))
    } else {
      dispatch(
        dispatch(
          bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STAFF(), staffData)
        )
      )
      dispatch(updateData(staffData, 2, multipleBookingStatus))
      // setTimeout(() => {
      //   dispatch(
      //     bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STEPS(), stepNo + 1)
      //   )
      // }, 100)
      // dispatch(updateVehicleData(staffData, !multipleBookingStatus))
    }
  }

  return (
    <>
      <div className="space-y-4 w-full">
        <h1>Select Vehicle Type</h1>
        <div className="flex flex-row flex-wrap justify-center sm:justify-start">
          {allVehicleList &&
            allVehicleList.map((value, key) => {
              return (
                <>
                  <div
                    key={key + "staff"}
                    className={` cursor-pointer h-[10rem] sm:w-[11rem] w-[9rem] m-3 flex flex-col items-center justify-center rounded-xl backdrop-blur-xl ${
                      staffCategory.id === value.id
                        ? "bg-yellow-medium_dark bg-opacity -35 shadow shadow-white"
                        : "border-2 border-yellow-light_medium"
                    } ${
                      value.isAvailable
                        ? ""
                        : "cursor-not-allowed opacity-60 blur-[1px] pointer-events-none"
                    } `}
                    title={`${
                      value.isAvailable
                        ? value.staffDisplayName
                        : "Not avalable For Selected location"
                    } `}
                    onClick={(e) => {
                      submitHandler(value)
                    }}
                  >
                    <img
                      src={value.staffImgUrl}
                      alt="staff"
                      className="object-cover w-32"
                      loading="lazy"
                    />
                    <span className="capitalize mt-2">
                      {value.staffDisplayName}
                    </span>
                    <span className="capitalize text-xs text-slate-100">
                      {value.description}
                    </span>
                  </div>
                </>
              )
            })}
        </div>
      </div>
    </>
  )
}

export default SelectStaff
