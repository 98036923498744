import { REDUX_STORE_DEFAULTS } from "../config"

const initialState = {
  bookingStepsLabel: [
    { stepNumber: 1, label: "Select Location" },
    { stepNumber: 2, label: "Select Vehicle" },
    { stepNumber: 3, label: "Select Category" },
    { stepNumber: 4, label: "Select Service" },
    { stepNumber: 5, label: "Extra Services" },
    { stepNumber: 6, label: "Date & Time" },
    { stepNumber: 7, label: "Information" },
    // { stepNumber: 7, label: "Confirmation" },
  ],
  stepNo: 1,
  location: "",
  staffCategory: "",
  serviceCategory: {},
  service: "",
  addOnService: [],
  bookingSlot: "",
  discount: {},
  coupon: "",
  giftCard: "",
  tax: 5,
  userInfo: "",
  otherUserInfo: "",
  otherVehicleInfo: "",
  confirmBooking: false,
  multipleBookingStatus: false,
  // Add other variables here
}

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STEPS():
      return {
        ...state,
        stepNo: action.payload,
      }

    case REDUX_STORE_DEFAULTS.UPDATE_BOOKING_LOCATION():
      return {
        ...state,
        location: action.payload,
      }

    // case REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STAFF():
    //   return {
    //     ...state,
    //     staffCategory: {
    //       ...state.staffCategory,
    //       id: action.payload,
    //     },
    //   }
    case REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STAFF():
      return {
        ...state,
        staffCategory: action.payload,
      }

    case REDUX_STORE_DEFAULTS.UPDATE_BOOKING_SERVICE_CATEGORY():
      return {
        ...state,
        serviceCategory: action.payload,
      }

    case REDUX_STORE_DEFAULTS.UPDATE_BOOKING_SERVICE():
      return {
        ...state,
        service: action.payload,
      }

    case REDUX_STORE_DEFAULTS.UPDATE_BOOKING_ADD_ON_SERVICE():
      return {
        ...state,
        addOnService: action.payload,
      }

    case REDUX_STORE_DEFAULTS.UPDATE_BOOKING_TIME_SLOT():
      return {
        ...state,
        bookingSlot: action.payload,
      }

    case REDUX_STORE_DEFAULTS.UPDATE_USER_INFO():
      return {
        ...state,
        userInfo: action.payload,
      }

    case REDUX_STORE_DEFAULTS.UPDATE_OTHER_USER_INFO():
      return {
        ...state,
        otherUserInfo: action.payload,
      }

    case REDUX_STORE_DEFAULTS.UPDATE_OTHER_VEHICLE_INFO():
      return {
        ...state,
        otherVehicleInfo: action.payload,
      }

    case REDUX_STORE_DEFAULTS.UPDATE_BOOKING_CONFIRMATION():
      return {
        ...state,
        confirmBooking: action.payload,
      }

    case REDUX_STORE_DEFAULTS.UPDATE_MULTIPLE_BOOKING():
      return {
        ...state,
        multipleBookingStatus: action.payload,
      }

    case REDUX_STORE_DEFAULTS.CLEAR_REDUX_DATA():
      return (state = initialState)

    case REDUX_STORE_DEFAULTS.UPDATE_DISCOUNT():
      return {
        ...state,
        discount: action.payload,
      }

    // case REDUX_STORE_DEFAULTS.UPDATE_PRICING():
    //   return {
    //     ...state,
    //     service: {
    //       ...state.service,
    //       servicePrice: action.payload,
    //     },
    //     addOnService: state.addOnService.map((service) => {
    //       const updatedService = action.payload.newAddOnServices.find(
    //         (newService) => newService.addOnServiceId === service.addOnServiceId
    //       )
    //       return updatedService
    //         ? { ...service, addOnPrice: updatedService.addOnPrice }
    //         : service
    //     }),
    //   }

    default:
      return state
  }
}

export default bookingReducer
