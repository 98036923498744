import React, { useEffect, useRef, useState } from "react"
import Calendar from "react-calendar"
import "./Calender.css"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import _, { lowerCase } from "lodash"
import {
  REDUX_STORE_DEFAULTS,
  REDUX_TOASTIFY_DEFAULTS,
} from "../../store/config"
import { bookingAction } from "../../store/actions/bookingAction"
import { AppointmentData } from "../../config/bookingDataConfig"
import { getBookingSlots } from "../../services/api/apiModule"
import { openToastify } from "../../store/actions/toastifyAction"

export const BookingCalender = () => {
  const dispatch = useDispatch()
  let {
    bookingStepsLabel,
    stepNo,
    location,
    staffCategory,
    service,
    addOnService,
    bookingSlot,
    allBookingData,
    multipleBookingStatus,
  } = useSelector((state) => state.bookingReducer)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [isLoading, setisLoading] = useState(false)
  const [allBookingSlot, setAllBookingSlot] = useState()
  const hasRunOnce = useRef(false)
  const [selectTime, setSelectTime] = useState()

  const [isMobileSlot, setIsMobileSlot] = useState()
  const [isShopSlot, setIsShopSlot] = useState()

  // const slotsData = AppointmentData[location.id].bookingSlots

  const mobileTimeSlots = [
    "8:00am",
    "8:30am",
    "9:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "3:00pm",
    "3:30pm",
    "4:00pm",
  ]
  const shopTimeSlots = [
    "9:30am",
    "9:45am",
    "10:00am",
    "12:30pm",
    "12:45pm",
    "1:00pm",
    "4:00pm",
    "4:15pm",
    "4:30pm",
  ]

  // Function to convert time slot to Date object
  const convertTimeSlotsToDate = (timeSlot, baseDate) => {
    const date = new Date(baseDate)
    const [time, modifier] = timeSlot.split(/(am|pm)/)
    let [hours, minutes] = time.split(":").map(Number)

    if (modifier === "pm" && hours !== 12) {
      hours += 12
    } else if (modifier === "am" && hours === 12) {
      hours = 0
    }

    date.setHours(hours)
    date.setMinutes(minutes)
    date.setSeconds(0)
    date.setMilliseconds(0)

    return date
  }

  const convertSlotData = (slotdata, timeSlots) => {
    const currentDate = new Date()
    let futureDate = undefined

    if (location.locationRole === "MOBILE") {
      futureDate = new Date(currentDate.setHours(currentDate.getHours() + 3))
    } else {
      futureDate = new Date(currentDate.setHours(currentDate.getHours() + 1))
    }

    return Object.keys(slotdata).map((key, index) => {
      const timeSlotDate = convertTimeSlotsToDate(
        timeSlots[index],
        selectedDate
      )
      const isSlotValue =
        slotdata[key] === 0 && timeSlotDate < futureDate ? 1 : slotdata[key]

      return {
        id: index,
        isSlot: isSlotValue,
        timeSlot: timeSlotDate,
      }
    })
  }

  const hitGetSlotApi = async (date, locationType) => {
    setisLoading(true)
    const params = {
      bookingDate: date,
      locationType: lowerCase(locationType),
    }
    // console.log(params)
    try {
      const response = await getBookingSlots(params)
      // console.log("getBookingSlots response", response)
      if (location.locationRole === "MOBILE") {
        setAllBookingSlot(
          convertSlotData(response.msg.slotdata, mobileTimeSlots)
        )
      } else {
        setAllBookingSlot(convertSlotData(response.msg.slotdata, shopTimeSlots))
      }
    } catch (error) {
      console.error(error)
      dispatch(
        openToastify(
          "Something went wrong!",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
        )
      )
    }
    setisLoading(false)
  }

  useEffect(() => {
    setAllBookingSlot()
    setisLoading(true)
    hitGetSlotApi(
      moment(selectedDate).format("YYYY-MM-DD"),
      location.locationRole
    )
    setTimeout(() => {
      // setisLoading(false)
    }, 900)
  }, [selectedDate])

  const handleDateChange = (date) => {
    const today = moment()
    if (location.id === 0 && today.day() === 0) {
      // 0 is Sunday
      // Set date to next Monday
      date = today.add(1, "days").startOf("isoWeek").toDate() // Add 1 day to today and start from Monday
    }
    // console.log("date", moment(date).format("YYYY-MM-DD"))
    if (date) {
      setSelectedDate(date)
    } else {
      setSelectedDate(new Date())
    }

    setSelectTime("")
    dispatch(bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_TIME_SLOT(), ""))
  }

  //Mon Jun 10 2024 00:00:00 GMT+0530 (India Standard Time)
  //2024-10-06T00:00:00+05:30
  useEffect(() => {
    //// console.log(moment(bookingSlot.date).format("ddd MM DD YYYY HH:mm:ss Z"))
    setSelectTime("")
    if (hasRunOnce.current) return

    if (!multipleBookingStatus) {
      handleDateChange()
    }
    setSelectTime(bookingSlot.id)
    hasRunOnce.current = true
  }, [])

  const handleSlot = (time) => {
    setSelectTime(time.id)
    // if (typeof time.time === "string") {
    //   const [startStr, endStr] = time.time.split("-").map((t) => t.trim())
    //   const startTime = moment(startStr, ["h:mma", "h:mmA"]).format("hh:mm A")
    //   const endTime = moment(endStr, ["h:mma", "h:mmA"]).format("hh:mm A")

    //  // console.log("Start Time:", startTime)
    //  // console.log("End Time:", endTime)

    // }
    const bookingTimeSlot = {
      id: time.id,
      date: moment(selectedDate).format("YYYY-MM-DD").toString(),
      createdAt: moment(new Date()).toString(),
      startTime: moment(time.timeSlot).toString(),
      endTime: moment(time.timeSlot).toString(),
    }
    if (bookingSlot.id === time.id) {
      setSelectTime("")
      dispatch(
        bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_TIME_SLOT(), "")
      )
    } else {
      dispatch(
        bookingAction(
          REDUX_STORE_DEFAULTS.UPDATE_BOOKING_TIME_SLOT(),
          bookingTimeSlot
        )
      )
    }

    // console.log(bookingSlot)
  }

  const addAnotherVehcile = () => {
    dispatch(
      bookingAction(REDUX_STORE_DEFAULTS.UPDATE_MULTIPLE_BOOKING(), true)
    )
    dispatch(bookingAction(REDUX_STORE_DEFAULTS.UPDATE_DISCOUNT(), 15))
    if (!multipleBookingStatus) {
      dispatch(bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STEPS(), 2))
      dispatch(
        dispatch(bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STAFF(), {}))
      )
    }
  }

  useEffect(() => {
    console.log(allBookingSlot, "allBookingSlot")
  }, [allBookingSlot])
  return (
    <div>
      <h1>Select Date and Time</h1>
      <div
        className={` w-full mt-3 flex justify-between md:flex-row flex-col ${
          multipleBookingStatus ? "pointer-events-none opacity-60" : ""
        }`}
      >
        <div className="flex-1 flex justify-center">
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            minDate={new Date()}
            tileDisabled={({ date }) => {
              if (location.id === 0) {
                return [0, 7].includes(date.getDay()) // Disable Sundays only
              } else {
                // return [0].includes(date.getDay()) // Disable None
              }
            }}
            maxDate={
              new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 2,
                new Date().getDate()
              )
            }
            intl="en-US"
          />
        </div>

        <div className="flex flex-col justify-between items-center mt-4 sm:mt-0 flex-1">
          <span>Select Time Slots :</span>
          {!isLoading ? (
            <div className="grid grid-cols-3 mt-2">
              {allBookingSlot &&
                allBookingSlot.map((data, key) => {
                  return (
                    <button
                      key={data.id}
                      className={` flex flex-col justify-center items-center text-sm border border-b-4 m-2 py-2 px-5 hover:shadow-md hover:shadow-white uppercase ${
                        selectTime === data.id
                          ? "bg-yellow-medium_dark bg-opacity- 60 shadow-md shadow-white"
                          : "hover:bg-yellow-light_medium"
                      } ${
                        data.isSlot === 0
                          ? "border-green-600"
                          : "pointer-events-none opacity-55 border-red-600"
                      }`}
                      onClick={() => {
                        handleSlot(data)
                      }}
                      title="Avalliable Slots"
                    >
                      <span>{moment(data.timeSlot).format("hh:mm A")}</span>
                      {/* <span>{data.availableSlots} Slots</span> */}
                    </button>
                  )
                })}
            </div>
          ) : (
            <div className="h-44">Loading Slots...</div>
          )}
          {bookingSlot !== "" ? (
            <div className="flex flex-col items-center space-y-1">
              <span className="text-xs">
                Get 15% Discount with 2nd Vehicle Booking
              </span>
              <button
                className="bg-yellow-medium_dark rounded-md py-2 px-10 font-medium hover:shadow-md hover:shadow-white"
                onClick={() => {
                  addAnotherVehcile()
                }}
              >
                Add Another Vehicle
              </button>
            </div>
          ) : (
            <div className="h-[3.8rem]"></div>
          )}
        </div>
      </div>

      {multipleBookingStatus ? (
        <div className="text-center pt-20">
          <span className="text-red-500 text-sm">
            Note: Date and Time Slot Will be the Same.
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
