import React, { useEffect, useState } from "react"
import logo from "../../asserts/boost-detailing-logo.png"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import { REDUX_STORE_DEFAULTS } from "../../store/config"
import { bookingAction } from "../../store/actions/bookingAction"
import { AppointmentData } from "../../config/bookingDataConfig"
import {
  updateBookingData,
  updateData,
  updateServiceData,
} from "../../store/actions/multipleBookingAction"

const SelectServiceCategory = () => {
  const dispatch = useDispatch()
  const [dropDownKey, setDropDownKey] = useState()
  // const [selectService, setSelectService] = useState()
  const [selectService, setSelectService] = useState({
    categoryId: "",
    serviceId: "",
  })
  const [allServicesList, setAllServicesList] = useState([])
  const [isReadMore, setIsReadMore] = useState()
  let {
    bookingStepsLabel,
    stepNo,
    location,
    staffCategory,
    service,
    allBookingData,
    multipleBookingStatus,
    serviceCategory,
  } = useSelector((state) => state.bookingReducer)

  useEffect(() => {
    if (Object.keys(staffCategory).length) {
      setAllServicesList(
        AppointmentData[location.id].staff[staffCategory.id].servicesList
      )
    }
  }, [])

  const handleCategory = (sCategProp) => {
    if (serviceCategory?.id === sCategProp.id) {
      dispatch(
        bookingAction(
          REDUX_STORE_DEFAULTS.UPDATE_BOOKING_SERVICE_CATEGORY(),
          {}
        )
      )
      return
    }
    // console.log(sCategProp)
    const categoryData = {
      id: sCategProp.id,
      categoryName: sCategProp.categoryDisplayName,
      categoryType: sCategProp.type,
      description: sCategProp.description,
      servCategoryImgUrl: sCategProp.servCategoryImgUrl,
    }

    setSelectService((categoryId) => ({
      ...categoryId,
      categoryId: sCategProp.id,
    }))

    dispatch(
      bookingAction(
        REDUX_STORE_DEFAULTS.UPDATE_BOOKING_SERVICE_CATEGORY(),
        categoryData
      )
    )
  }

  const handleService = (sCategProp, serviceProp) => {
    const serviceData = {
      id: sCategProp.id,
      categoryName: sCategProp.categoryDisplayName,
      categoryType: sCategProp.type,
      description: sCategProp.description,
      servCategoryImgUrl: sCategProp.servCategoryImgUrl,
      serviceId: serviceProp.id,
      serviceName: serviceProp.serviceDisplayName,
      serviceShortDiscription: serviceProp.serviceShortDiscription,
      servicePrice: serviceProp.price,
    }

    setSelectService((categoryId) => ({
      ...categoryId,
      categoryId: sCategProp.id,
    }))
    setSelectService((serviceId) => ({
      ...serviceId,
      serviceId: serviceProp.id,
    }))
    // setSelectService(serviceProp.id)
    //// console.log("sServices", sCategProp, serviceProp.id, selectService)
    //// console.log("s", selectService)

    if (
      serviceProp.id === selectService.serviceId &&
      sCategProp.id === selectService.categoryId
    ) {
      // console.log(true)
      dispatch(
        dispatch(
          bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_SERVICE(), {})
        )
      )
      setSelectService(
        (categoryId, serviceId) => (
          {
            ...categoryId,
            categoryId: "",
          },
          {
            ...serviceId,
            serviceId: "",
          }
        )
      )
      dispatch(updateData({}, 3))
    } else {
      dispatch(
        dispatch(
          bookingAction(
            REDUX_STORE_DEFAULTS.UPDATE_BOOKING_SERVICE(),
            serviceData
          )
        )
      )

      dispatch(updateData(serviceData, 3))

      // setTimeout(() => {
      //   dispatch(
      //     bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STEPS(), stepNo + 1)
      //   )
      // }, 100)
    }
  }

  return (
    <>
      <h1>Select Service Category</h1>
      {allServicesList &&
        allServicesList.map((value, key) => {
          return (
            <>
              <div
                key={key + "serviceCat"}
                className={` border-2 mt-2 rounded flex justify-between items-center p-2 h-[9rem] cursor-pointer relative z-10 overflow-hidden ${
                  serviceCategory?.id === value.id
                    ? "bg-yellow-medium_dark bg-opacity- 35"
                    : "border-yellow-light"
                } ${value.isAvailable ? "" : "hidden"}`}
                onClick={() => {
                  handleCategory(value)
                }}
              >
                <div className="">
                  <img
                    src={
                      value.servCategoryImgUrl ? value.servCategoryImgUrl : logo
                    }
                    alt=""
                    className="md: w-[10rem] w- [7rem] h-[8rem] object-cover"
                  />
                </div>
                <div className="p -2 w-40 32 text-right pr-4 pb-3 h-full flex flex-col flex-wrap justify-end">
                  <h1>{value.categoryDisplayName}</h1>
                  <p className="text-xs">
                    {_.truncate(value.description, {
                      length: 10,
                    })}
                  </p>
                </div>

                {value.ribbon && (
                  <div className="absolute w-[10rem] text-center top-[1.5rem] -right-[3rem] text-white bg-yellow-light rotate-45 capitalize shadow-md shadow-white">
                    <span>{value.ribbon}</span>
                  </div>
                )}

                {/* <div className="flex-1 flex items-center justify-end pr-6">
                    <svg
                      className={` w-6 h-6 transform duration-200 ${
                        value.id === dropDownKey ? "rotate-90" : ""
                      } `}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </div> */}
              </div>
            </>
          )
        })}
    </>
  )
}

export default SelectServiceCategory
