import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { bookingAction } from "../../store/actions/bookingAction"
import { openToastify } from "../../store/actions/toastifyAction"
import {
  REDUX_STORE_DEFAULTS,
  REDUX_TOASTIFY_DEFAULTS,
} from "../../store/config"
import Logo from "../../asserts/boost-detailing-logo.png"
import {
  createMobileBooking,
  createShopBooking,
  getDiscount,
} from "../../services/api/apiModule"
import { lowerCase, upperCase } from "lodash"
import {
  updateData,
  updatePriceData,
} from "../../store/actions/multipleBookingAction"
import moment from "moment"

const SummarySection = () => {
  const dispatch = useDispatch()
  let {
    bookingStepsLabel,
    stepNo,
    location,
    staffCategory,
    serviceCategory,
    service,
    addOnService,
    bookingSlot,
    userInfo,
    otherUserInfo,
    otherVehicleInfo,
    multipleBookingStatus,
    confirmBooking,
  } = useSelector((state) => state.bookingReducer)

  let { allBookingData } = useSelector((state) => state.multipleBookingReducer)

  const [addonPrice, setAddonPrice] = useState(0)
  const [subTotalPrice, setSubTotalPrice] = useState(0)
  const [taxPrice, setTaxPrice] = useState(0)
  const [discountValue, setDiscountValue] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [extraCharges, setExtraCharges] = useState(0)
  const [isLenght, setIsLength] = useState(1)
  const [isVehicleInfo, setIsVehicleInfo] = useState(otherVehicleInfo ?? {})

  const [apiPricing, setApiPricing] = useState()
  const [isEnterDiscount, setIsEnterDiscount] = useState()
  const [isDiscountAmount, setIsDiscountAmount] = useState(0)
  const ShopCouponName = "SHOP20"
  const MobileCouponName = "MOBILE10"
  const MultiCouponName = "MULTI15"

  const calculateServicePrice = () => {
    let service_price = 0
    let discount = 0
    let calDiscount = 0

    //multiple booing
    if (allBookingData.length) {
      let bookingSubTotalPrice = 0

      allBookingData.forEach((booking) => {
        // console.log("service_prices", booking.subTotal)

        bookingSubTotalPrice += booking.subTotal

        // console.log("bookingSubTotalPrice", bookingSubTotalPrice)
        if (bookingSubTotalPrice) {
          // console.log(bookingSubTotalPrice, "Price", allBookingData)
          setSubTotalPrice(bookingSubTotalPrice)

          // Calculate discount
          if (isDiscountAmount === 0) {
            if (location.id === 0) {
              calDiscount = (bookingSubTotalPrice * 20) / 100
              setDiscountValue(20)
            } else {
              calDiscount = (bookingSubTotalPrice * 10) / 100
              setDiscountValue(10)
            }
          } else {
            calDiscount = isDiscountAmount
          }

          // Apply discount to get discounted total
          const discountedTotal = bookingSubTotalPrice - calDiscount

          // Calculate tax on the discounted total
          const calTaxPrice = (discountedTotal * 5) / 100
          setTaxPrice(calTaxPrice.toFixed(2))

          // Calculate the final total price
          const calTotalPrice = discountedTotal + calTaxPrice + extraCharges
          setTotalPrice(calTotalPrice.toFixed(1))
        } else {
          setSubTotalPrice(0)
          setDiscountValue(0)
          setTaxPrice(0)
          setTotalPrice(0)
        }
      })
    } else {
    }
  }

  useEffect(() => {
    // Function to calculate total price
    // console.log(allBookingData, "allBookingData")
    if (service.serviceName) {
      // Function to calculate service price
    }
    calculateServicePrice()
  }, [
    addOnService,
    service?.serviceName,
    extraCharges,
    otherVehicleInfo,
    stepNo,
    allBookingData,
  ])

  //function to empty data
  useEffect(() => {
    // console.log("staffCategory?.id", service.serviceName)
    if (service.serviceName || staffCategory.staffDisplayName) {
      dispatch(
        bookingAction(
          REDUX_STORE_DEFAULTS.UPDATE_BOOKING_SERVICE_CATEGORY(),
          {}
        )
      )
      dispatch(bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_SERVICE(), {}))
      dispatch(
        bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_ADD_ON_SERVICE(), [])
      )
      setAddonPrice(0)
      setSubTotalPrice(0)
      setTaxPrice(0)
      setDiscountValue(0)
      setTotalPrice(0)
      setExtraCharges(0)
      setIsLength(1)
    }
  }, [staffCategory?.id])

  useEffect(() => {
    if (serviceCategory.categoryName) {
      dispatch(bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_SERVICE(), {}))
      dispatch(
        bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_ADD_ON_SERVICE(), [])
      )
      setAddonPrice(0)
      setSubTotalPrice(0)
      setTaxPrice(0)
      setDiscountValue(0)
      setTotalPrice(0)
      setExtraCharges(0)
      // setIsLength(1)
    }
  }, [serviceCategory?.id])

  useEffect(() => {
    if (service.serviceName) {
      dispatch(
        bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_ADD_ON_SERVICE(), [])
      )
      setAddonPrice(0)
      setSubTotalPrice(0)
      setTaxPrice(0)
      setDiscountValue(0)
      setTotalPrice(0)
      setExtraCharges(0)
      // setIsLength(1)
      setIsEnterDiscount("")
      setIsDiscountAmount(0)
    }
  }, [service?.servicePrice])

  useEffect(() => {
    dispatch(
      dispatch(bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STAFF(), {}))
    )
    dispatch(updateData([], 1))
  }, [location.id])

  useEffect(() => {
    // console.log(service.serviceId, "service.serviceId")
    dispatch(
      bookingAction(REDUX_STORE_DEFAULTS.UPDATE_OTHER_VEHICLE_INFO(), {})
    )
  }, [service.serviceId, staffCategory?.id])

  //power supply extra carges
  useEffect(() => {
    if (otherUserInfo?.powerSupply === "no") {
      setExtraCharges(15)
    } else {
      setExtraCharges(0)
    }

    if (otherUserInfo?.conset === "I agree" && isEnterDiscount === "") {
      hitdiscountApi()
    }
  }, [otherUserInfo])

  const checkPricing = () => {
    if (totalPrice !== apiPricing?.TotalPrice) {
      dispatch(
        openToastify(
          "Something Went wrong!",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
        )
      )
    }
  }
  //calc pricing
  const hitCalculatePriceApi = async () => {
    if (location.id === 0) {
      const ids = addOnService.map((service) => service?.addOnServiceId)
      // console.log(ids, "ids")

      const parms = {
        firstName: userInfo.name,
        phoneNumber: userInfo.phoneNumber,
        email: userInfo.email,
        carType: staffCategory.type,
        serviceName: service.categoryType,
        serviceId: service.serviceId,
        additionalServiceList: ids,
      }
      const response = await createShopBooking(parms)
      // console.log("response", response.data)
      setApiPricing(response.data)
    } else {
      const ids = addOnService.map((service) => service?.addOnServiceId)
      // console.log(ids, "ids")

      const parms = {
        firstName: userInfo.name,
        phoneNumber: userInfo.phoneNumber,
        email: userInfo.email,
        carType: staffCategory.type,
        serviceName: service.categoryType,
        serviceId: service.serviceId,
        additionalServiceList: ids,
        pricingType: "",
        totalLength: 0,
      }
      const response = await createMobileBooking(parms)
      // console.log("response", response.data)
      setApiPricing(response.data)
    }
    checkPricing()
  }

  const hitdiscountApi = async () => {
    let enteredCouponName = upperCase(isEnterDiscount)
    if (!isEnterDiscount) {
      if (location.id === 0) {
        enteredCouponName = ShopCouponName
      } else {
        enteredCouponName = MobileCouponName
      }
      if (allBookingData.length > 1) {
        enteredCouponName = MultiCouponName
      }
      setIsEnterDiscount(enteredCouponName)
    }
    enteredCouponName = enteredCouponName.replace(/\s/g, "")

    if (enteredCouponName) {
      if (enteredCouponName === MultiCouponName && !multipleBookingStatus) {
        dispatch(
          openToastify(
            "Add Another Booking For this Coupon",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
        return
      }
      const params = {
        couponName: enteredCouponName,
        totalAmount: subTotalPrice,
        locationType: lowerCase(location.locationRole),
      }
      // console.log("isEnterDiscount", isEnterDiscount)

      try {
        const response = await getDiscount(params)
        // console.log("hitdiscountApi", response)
        if (response.success) {
          dispatch(
            bookingAction(REDUX_STORE_DEFAULTS.UPDATE_DISCOUNT(), {
              name: params.couponName,
              amount: response.msg.discountAmt,
            })
          )
          setIsDiscountAmount(response.msg.discountAmt)
          calculateServicePrice()
          dispatch(
            openToastify(
              "Coupon Applied Sucessfully",
              REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
            )
          )
        }
      } catch (error) {
        // console.log(error)
        resetDiscount()
        dispatch(
          openToastify(
            error.response.data.msg,
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
      }
    }
  }

  const handleDiscountButton = () => {
    if (stepNo === 7) {
      // console.log(true)
      hitdiscountApi()
    } else {
      dispatch(
        openToastify(
          "Complete All Steps",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
        )
      )
    }
    // console.log(bookingStepsLabel, stepNo)
  }

  // useEffect(() => {
  //   if (stepNo === 7) {
  //     // hitCalculatePriceApi()
  //     hitdiscountApi()
  //   }
  // }, [stepNo])

  const resetDiscount = () => {
    setIsEnterDiscount("")
    setIsDiscountAmount(0)
  }

  useEffect(() => {
    calculateServicePrice()
  }, [isDiscountAmount])

  useEffect(() => {
    if (!confirmBooking) {
      resetDiscount()
    }
  }, [confirmBooking])

  //add vehicle info
  const handleVehicleInfo = (name, value) => {
    setIsLength(value)
    console.log("handleVehicleInfo", name, value)

    setIsVehicleInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }))
  }

  useEffect(() => {
    console.log(isLenght, "isVehicleInfo=", isVehicleInfo)

    setTimeout(() => {
      dispatch(
        bookingAction(
          REDUX_STORE_DEFAULTS.UPDATE_OTHER_VEHICLE_INFO(),
          isVehicleInfo
        )
      )
      dispatch(updatePriceData(isVehicleInfo))
    }, 300)
  }, [isVehicleInfo])

  return (
    <>
      <div className="lg:w-[39%] p-5 border lg:overflow-y-scroll summary-scroll flex-2">
        <div className="flex items-center justify-between">
          <h3 className="text-2xl font-medium">Summary</h3>
        </div>

        <div className="sm:p-5 space-y-4">
          <div className="flex justify-between space-x-6 items-center">
            <h2>Selected Location:</h2>
            <p className="bg-yellow-light bg-opacity-30 flex-1 rounded-md p-1 text-sm capitalize">
              {location.displayName ? location.displayName : "Location"}
            </p>
          </div>
          <div className="flex justify-between space-x-6 items-center">
            <span className="flex- 1 text- sm text-right pr-3">
              Appointment Time:
            </span>
            {bookingSlot ? (
              <span className="flex-1 text-sm">
                {moment(bookingSlot.date).format("DD-MM-YYYY")} -{" "}
                {moment(bookingSlot.startTime).format("hh:mm A")}
              </span>
            ) : (
              <></>
            )}
          </div>

          <div className="flex flex-col overflow- x-scroll h- [28rem] summary-scroll divide-y-2 pb-2">
            {allBookingData.map((data, index) => {
              const { vehicle } = data
              return (
                <div className="flex flex-col py-5 lg:px-5">
                  {allBookingData.length > 1 ? (
                    <span className="text-lg text-yellow-light">
                      Vehicle: {index + 1}
                    </span>
                  ) : (
                    <></>
                  )}
                  <div className="h-20 lg:w-[30rem] w-[20rem]">
                    <h2>Selected Vehicle:</h2>
                    <div className="flex space-x-1">
                      <p>
                        {data?.vehicle?.staffDisplayName
                          ? data?.vehicle?.staffDisplayName
                          : ""}
                      </p>
                      <img
                        src={
                          data?.vehicle?.staffImgUrl
                            ? data?.vehicle?.staffImgUrl
                            : Logo
                        }
                        alt="vehImage"
                        className={` ${
                          data?.vehicle?.staffImgUrl ? "w-28" : "w-10"
                        }`}
                      />
                    </div>
                  </div>

                  {stepNo > 3 && (
                    <>
                      {vehicle.id === 5 && (
                        <div className="w-full flex justify-end items-center space-x-2">
                          <span className="text-sm">
                            Enter Length(in feet):
                          </span>
                          <button
                            name="rvLength"
                            onClick={(e) => {
                              handleVehicleInfo(e.target.name, isLenght - 1)
                            }}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            className="border-2 w-7 text-center"
                            value={otherVehicleInfo?.rvLength ?? isLenght}
                            name="rvLength"
                            min={1}
                            onChange={(e) => {
                              handleVehicleInfo(e.target.name, e.target.value)
                            }}
                          />
                          <button
                            name="rvLength"
                            onClick={(e) => {
                              handleVehicleInfo(e.target.name, isLenght + 1)
                            }}
                          >
                            +
                          </button>
                        </div>
                      )}
                      {vehicle.id === 7 && (
                        <div className="w-full flex justify-end items-center space-x-2">
                          <span className="text-sm">Enter No of Seats:</span>
                          <button
                            name="busSeats"
                            onClick={(e) => {
                              handleVehicleInfo(e.target.name, isLenght - 1)
                            }}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            className="border-2 w-7 text-center"
                            value={otherVehicleInfo?.busSeats ?? isLenght}
                            name="busSeats"
                            min={1}
                            onChange={(e) => {
                              handleVehicleInfo(e.target.name, e.target.value)
                            }}
                          />
                          <button
                            name="busSeats"
                            onClick={(e) => {
                              handleVehicleInfo(e.target.name, isLenght + 1)
                            }}
                          >
                            +
                          </button>
                        </div>
                      )}
                      {vehicle.id === 8 && (
                        <div className="w-full flex justify-end items-center space-x-2">
                          <span className="text-sm">
                            Enter Length(in feet):
                          </span>
                          <button
                            name="boatLength"
                            onClick={(e) => {
                              handleVehicleInfo(e.target.name, isLenght - 1)
                            }}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            className="border-2 w-7 text-center"
                            value={otherVehicleInfo?.boatLength ?? isLenght}
                            name="boatLength"
                            min={1}
                            onChange={(e) => {
                              handleVehicleInfo(e.target.name, e.target.value)
                            }}
                          />
                          <button
                            name="boatLength"
                            onClick={(e) => {
                              handleVehicleInfo(e.target.name, isLenght + 1)
                            }}
                          >
                            +
                          </button>
                        </div>
                      )}
                      {vehicle.id === 10 && (
                        <div className="w-full flex justify-end items-center space-x-2">
                          <span className="text-sm">
                            Enter Length(in Sq.feet):
                          </span>
                          <button
                            name="houseSqFeet"
                            onClick={(e) => {
                              handleVehicleInfo(e.target.name, isLenght - 1)
                            }}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            className="border-2 w-7 text-center"
                            value={otherVehicleInfo?.houseSqFeet ?? isLenght}
                            name="houseSqFeet"
                            min={1}
                            onChange={(e) => {
                              handleVehicleInfo(e.target.name, e.target.value)
                            }}
                          />
                          <button
                            name="houseSqFeet"
                            onClick={(e) => {
                              handleVehicleInfo(e.target.name, isLenght + 1)
                            }}
                          >
                            +
                          </button>
                        </div>
                      )}
                    </>
                  )}

                  <div className="h- 20 pr-5">
                    <div className="flex justify-between">
                      <h2>Selected Service:</h2>
                      <div className="flex space-x-1">
                        <h3>Price</h3>
                      </div>
                    </div>
                    <div className="text-sm flex items-center justify-between">
                      <div className="text-xs md:text-sm">
                        <h1>
                          {data?.service?.categoryName} -{" "}
                          {data?.service?.serviceName ??
                            data?.service?.serviceShortDiscription}
                        </h1>
                        {/* <p>{data?.service?.serviceShortDiscription}</p> */}
                      </div>
                      <p className="space-x-2 w-[10rem] flex justify-end">
                        <span>
                          {data.vehicle.id === 5 &&
                          otherVehicleInfo?.rvLength > 1 ? (
                            <span>length: {otherVehicleInfo.rvLength} X</span>
                          ) : (
                            <></>
                          )}
                          {data.vehicle.id === 8 &&
                          otherVehicleInfo?.boatLength > 1 ? (
                            <span>
                              length: {otherVehicleInfo?.boatLength} X
                            </span>
                          ) : (
                            <></>
                          )}
                          {data.vehicle.id === 7 &&
                          otherVehicleInfo?.busSeats > 0 ? (
                            <span>
                              No of Seats: {otherVehicleInfo.busSeats} X
                            </span>
                          ) : (
                            <></>
                          )}
                          {data.vehicle.id === 10 &&
                          otherVehicleInfo?.houseSqFeet > 0 ? (
                            <span>
                              House Sq-feet: {otherVehicleInfo.houseSqFeet}X
                            </span>
                          ) : (
                            <></>
                          )}
                        </span>
                        <span>
                          $
                          {data?.service?.servicePrice
                            ? data?.service?.servicePrice
                            : 0}
                        </span>
                        {/* {otherVehicleInfo.rvLength>1 && (
                          <span>
                            =
                            {otherVehicleInfo.rvLength *
                              data?.service?.servicePrice}
                          </span>
                        )} */}
                      </p>
                    </div>
                  </div>
                  {data?.addOnService?.length ? (
                    <div className="space-y-1 mt-3">
                      <h2>
                        Selected Add-on:
                        {addOnService.length ? (
                          <span>x{addOnService.length}</span>
                        ) : (
                          <></>
                        )}
                      </h2>
                      <div className="sm:h-28 overflow-y-scroll summary-scroll space-y-3 bg-yellow-light bg-opacity-15 p-2 rounded-md">
                        {data?.addOnService &&
                          data?.addOnService.map((value, index) => {
                            return (
                              <div className="text-sm border p-1 rounded flex justify-between">
                                <div className="flex space-x-1">
                                  <span>{index + 1}.</span>
                                  <div>
                                    <h1>{value.addOnServiceName}</h1>
                                    <p>{value.description}</p>
                                  </div>
                                </div>
                                <p>${value.addOnPrice}</p>
                              </div>
                            )
                          })}
                        {/* <div className="text-sm">
                <h1>Addon-Services</h1>
                <p>description</p>
              </div>
              <div className="text-sm">
                <h1>Addon-Services</h1>
                <p>description</p>
              </div> */}
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-center">
                      <span>No addon Data Found</span>
                    </div>
                  )}
                  <div className="space-y- 2 pt-5">
                    {data?.addOnService?.length ? (
                      <div className="flex justify-between">
                        <span>AddOn Total:</span>
                        <span>
                          ${data?.totalAddOnPrice ? data?.totalAddOnPrice : 0}{" "}
                          CAD
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* multiple service */}
                  <div className="flex justify-between">
                    <span>Sub Total ({index + 1}):</span>
                    <span>
                      ${data.subTotal ? data.subTotal : 0}
                      CAD
                    </span>
                  </div>
                </div>
              )
            })}
            {allBookingData.length < 1 ? (
              <div className="flex flex-col py-5 lg:px-5">
                <div className="h-20 lg:w-[30rem] w-[20rem]">
                  <h2>Selected Vehicle:</h2>
                  <div className="flex space-x-1">
                    <img src={Logo} alt="vehImage" className="w-10" />
                  </div>
                </div>
                <div className="h-20 pr-5">
                  <div className="flex justify-between">
                    <h2>Selected Service:</h2>
                    <div className="flex space-x-1">
                      <h3>Price</h3>
                    </div>
                  </div>
                  <div className="text-sm flex items-center justify-between">
                    <div>
                      <h1>na</h1>
                    </div>
                    <p className="space-x-2 w-[10rem] flex justify-end">
                      <span>$0</span>
                    </p>
                  </div>
                </div>

                <div className="space-y-1">
                  <h2>Selected Add-on:</h2>
                </div>
                <div className="flex justify-center">
                  <span>No addon Data Found</span>
                </div>
                <div className="space-y- 2 pt-5">
                  <div className="flex justify-between">
                    <span>AddOn Total:</span>
                    <span>$0 CAD</span>
                  </div>
                </div>
                {/* multiple service */}
                <div className="flex justify-between">
                  <span>Sub Total:</span>
                  <span>$0 CAD</span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          {/* total Calculation */}
          <div className="space-y-2 pt-5">
            <div className="flex justify-between">
              <span>Sub Total:</span>
              <span>${subTotalPrice ? subTotalPrice : 0} CAD</span>
            </div>

            {/* discount */}
            <div className="flex py-5 justify-end">
              <input
                type="text"
                className={` h-10 w-[20rem] rounded-lg px-2 border ${
                  isEnterDiscount ? "border-yellow-light" : "border-2"
                } `}
                placeholder="Enter Discount/Gift Card"
                value={isEnterDiscount}
                onChange={(e) => {
                  setIsEnterDiscount(e.target.value)
                }}
              />
              {isEnterDiscount ? (
                <button
                  className="flex items-center -ml-6 cursor-pointer"
                  onClick={() => {
                    resetDiscount()
                  }}
                >
                  <svg
                    className="size-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              ) : (
                <></>
              )}

              <div className="flex ml-2">
                {isDiscountAmount ? (
                  <button
                    className="bg-yellow-medium_dark px-5 uppercase w-24"
                    onClick={() => {}}
                  >
                    Applied
                  </button>
                ) : (
                  <button
                    className="bg-yellow-medium_dark hover:bg-white border-2 text-white hover:text-black px-5 uppercase w-24"
                    onClick={() => {
                      handleDiscountButton()
                    }}
                  >
                    Apply
                  </button>
                )}
              </div>
            </div>
            <div className="text-sm text-red-500">
              <p>Note: Additional Charges if: Pet Hair / Mold</p>
            </div>
            <div className="flex justify-between">
              <span>
                {discountValue === 0 ? "" : discountValue + "%"} Discount:
              </span>
              <span
                className={`${
                  isDiscountAmount || discountValue ? "text-red-600" : ""
                } `}
              >
                -$
                {isDiscountAmount === 0
                  ? (subTotalPrice * discountValue) / 100
                  : isDiscountAmount}
                CAD
              </span>
            </div>
            <div className="flex justify-between">
              <span>Tax +5%:</span>
              <span>${taxPrice ? taxPrice : 0} CAD</span>
            </div>
            {extraCharges != 0 && (
              <div className="flex justify-between">
                <span>Power Supply Charges</span>
                <span>${extraCharges} CAD</span>
              </div>
            )}
            <div className="flex justify-between font-medium text-green-600">
              <span>Total:</span>
              <span>${totalPrice ? totalPrice : 0} CAD</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SummarySection
