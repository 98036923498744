import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import { REDUX_STORE_DEFAULTS } from "../../store/config"
import { bookingAction } from "../../store/actions/bookingAction"
import { AppointmentData } from "../../config/bookingDataConfig"
import {
  updateAddOnServiceData,
  updateData,
  updatePriceData,
} from "../../store/actions/multipleBookingAction"
import logo from "../../asserts/boost-detailing-logo.png"

const SelectExtraService = () => {
  const dispatch = useDispatch()
  const [selectedService, setSelectedService] = useState([])
  let {
    bookingStepsLabel,
    stepNo,
    location,
    staffCategory,
    service,
    addOnService,
    multipleBookingStatus,
    otherVehicleInfo
  } = useSelector((state) => state.bookingReducer)

  const [addOnServiceList, setAddOnServiceList] = useState()

  useEffect(() => {
    setSelectedService(addOnService)

    if (Object.keys(service).length) {
      // console.log("extra", service, Object.keys(service).length)
      setAddOnServiceList(
        AppointmentData[location.id].staff[staffCategory.id]?.servicesList[
          service.id
        ].services[service.serviceId].addonService
      )
    }

    if (
      AppointmentData[location?.id]?.staff[staffCategory?.id]?.servicesList[
        service?.id
      ]?.services[service?.serviceId]?.addonService.length === 0
    ) {
      dispatch(
        bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STEPS(), stepNo + 1)
      )
    }
  }, [])

  const handleAddon = (props) => {
    const addOnServiceData = {
      addOnServiceId: props.id,
      addOnServiceName: props.serviceDisplayName,
      description: props.serviceShortDiscription,
      addOnPrice: props.price,
    }

    setSelectedService((prev) => {
      prev = prev || []
      const index = prev.findIndex((item) => item.addOnServiceId === props.id)
      if (index !== -1) {
        return prev.filter((_, indexId) => indexId !== index)
      } else {
        return [...prev, addOnServiceData]
      }
    })
  }

  useEffect(() => {
    // console.log("selectedService", selectedService)
    if (selectedService.length) {
      dispatch(
        bookingAction(
          REDUX_STORE_DEFAULTS.UPDATE_BOOKING_ADD_ON_SERVICE(),
          selectedService
        )
      )
    } else {
      dispatch(
        bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_ADD_ON_SERVICE(), [])
      )
    }
    dispatch(updateData(selectedService, 4))
    dispatch(updatePriceData(otherVehicleInfo))
  }, [selectedService])

  return (
    <>
      <h1>Select Add-On (Note: Additional Charges if: Pet Hair / Mold)</h1>
      {addOnServiceList &&
        addOnServiceList.map((value, index) => {
          return (
            <div
              key={index + "addOn"}
              className={` p-2 mt-2 border-2 rounded flex justify-between items-center h- 20 cursor-pointer relative z-10 overflow-hidden ${
                addOnService.some((item) => item.addOnServiceId === value.id)
                  ? "bg-yellow-medium_dark bg-opacity- 35 shadow-white shadow-md"
                  : "border-yellow-light_medium"
              } `}
              onClick={() => {
                handleAddon(value)
              }}
            >
              <div className="flex justify-between h-full w-full">
                <div className="">
                  <img
                    src={value.imageUrl ? value.imageUrl : logo}
                    alt=""
                    className="md: w-[13rem] w- [7rem] h-[9rem] object-cover"
                  />
                </div>

                <div className="flex md:space-x-3 items-center justify-between flex-col md:flex-row md:w-full pl-10">
                  <div className="w-36 md:w-auto text-right">
                    <h1 className="md:text-xl">{value.serviceDisplayName}</h1>
                    <p className="text-xs">{value.serviceDiscription}</p>
                  </div>

                  <div className="md:pr-10 h-full w-full md:w-auto flex flex-col justify-center text-right">
                    {value.price > 0 && (
                      <span className="text-lg">${value.price}</span>
                    )}
                    {value.oldPrice > 0 && (
                      <del className="font-normal text-red-600">
                        ${value.oldPrice}
                      </del>
                    )}
                  </div>
                </div>

                <div className="absolute w-[10rem] text-center top-[1.5rem] -right-[3rem] text-white bg-yellow-light rotate-45 capitalize shadow-md shadow-white">
                  <span>{value.ribbon}</span>
                </div>
              </div>
            </div>
          )
        })}
    </>
  )
}

export default SelectExtraService
