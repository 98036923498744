import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../asserts/logo.gif";
import logoSplash from "../asserts/logoSplash.webm";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/booking");
    setTimeout(() => {
    }, 5800);
  }, []);

  return (
    <>
      <div className="flex h-full w-full justify-center items-center"></div>
      <div className="fixed inset-0 bg- black flex justify-center items-center">
        <video autoPlay loop muted playsInline>
          <source
            src="https://res.cloudinary.com/dsprifizw/video/upload/v1718938408/annimation_qcofrn.webm"
            type="video/webm"
          />
        </video>
      </div>
    </>
  );
};

export default Home;
