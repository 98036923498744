import mobileLoacation from "../asserts/delivery-van.svg"
import shopLoacation from "../asserts/car-wash.svg"

const Checked = (
  <svg
    className="size-5 text-green-600"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="m4.5 12.75 6 6 9-13.5"
    />
  </svg>
)

const Cross = (
  <svg
    className="size-5 text-red-600"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M6 18 18 6M6 6l12 12"
    />
  </svg>
)

export const AppointmentData = [
  {
    // shop
    id: 0,
    displayName: "Appointment Shop",
    locationRole: "SHOP",
    imgUrl: shopLoacation,
    description: "5680 Dorset St Burnaby",
    ribbon: "20% discount",
    locationDiscount: 15,
    bookingSlots: [
      {
        id: 0,
        time: "11:00am-12:00pm",
        availableSlots: 3,
      },
      {
        id: 1,
        time: "12:00pm-01:00pm",
        availableSlots: 3,
      },
      {
        id: 2,
        time: "01:00pm-02:00pm",
        availableSlots: 3,
      },
      {
        id: 3,
        time: "01:00pm-02:00pm",
        availableSlots: 3,
      },
      {
        id: 4,
        time: "01:00pm-02:00pm",
        availableSlots: 3,
      },
    ],
    staff: [
      //Car/Sedan
      {
        id: 0,
        staffDisplayName: "Car/Sedan",
        type: "car",
        isAvailable: true,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719277375/car_sedan-removebg-preview_p9vpjb.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "Ceramic Coating",
            type: "ceramicCoating",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/01/testimonial-detailing.jpg",
            ribbon: "POPULAR",
            isAvailable: true,
            description: "coating",
            services: [
              {
                id: 0,
                serviceDisplayName: "Diamond",
                serviceDiscription: (
                  <>
                    <h1>Diamond (Lifetime Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Engine Bay Detail</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>4 Coats of Ceramic Pro 9H on the Paint</li>
                      <li>
                        1 Coat of Ceramic Pro Top Coat on the Paint - Glass
                        Coating
                      </li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Diamond (Lifetime Warranty)",
                price: 1000.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 400.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Gold",
                serviceDiscription: (
                  <>
                    <h1>Gold (5 Years Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>2 Coats of Ceramic Pro 9H on the Paint</li>
                      <li>
                        1 Coat of Ceramic Pro Top Coat on the Paint - Glass
                        Coating
                      </li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Gold (5 Years Warranty)",
                price: 750.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 400.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Silver",
                serviceDiscription: (
                  <>
                    <h1>Silver (2 Years Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>2 Coats of Ceramic Coating on the Paint</li>
                      <li>Glass Coating</li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Silver (2Years Warranty)",
                price: 600.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 400.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 1,
            categoryDisplayName: "Paint Correction",
            type: "paintCorrection",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/paint-11-scaled.jpg",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Level 2",
                serviceDiscription: (
                  <>
                    <h1>Level 2 Paint Correction</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                    </ul>
                    <strong>CORRECTION</strong>
                    <p>
                      <strong>Duration:</strong> 2 to 3 Hours spent on enhancing
                      the paintwork
                    </p>
                    <p>
                      <strong>Action:</strong> Removes Light to Medium Scratches
                      and Swirl Marks
                    </p>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Rewash to remove any dust or compound</li>
                      <li>Light coat of Wax</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This process will not remove deep
                      Scratches that are beyond the clear coat.
                    </p>
                  </>
                ),
                serviceShortDiscription: "LEVEL 2 PAINT CORRECTION PREPARATION",
                price: 500.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 1,
                serviceDisplayName: "Level 3",
                serviceDiscription: (
                  <>
                    <h1>LEVEL 3 PAINT CORRECTION</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                    </ul>
                    <strong>CORRECTION</strong>
                    <p>
                      <strong>Duration:</strong> 4 to 6 Hours spent on enhancing
                      the paintwork
                    </p>
                    <p>
                      <strong>Action:</strong> Removes Medium to Heavy Scratches
                      and Swirl Marks, Oxidation
                    </p>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Rewash to remove any dust or compound</li>
                      <li>Light coat of Wax</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This process will not remove deep
                      Scratches that are beyond the clear coat.
                    </p>
                  </>
                ),
                serviceShortDiscription: "LEVEL 3 PAINT CORRECTION PREPARATION",
                price: 600.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
            ],
          },
          {
            id: 2,
            categoryDisplayName: "Interior & Exterior Detailing",
            type: "interiorAndExteriorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/06/Car-Detailing-in-Maple-Ridge-2.jpg",
            ribbon: "POPULAR",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "A to Z Auto Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "A to Z Auto Detailing - Car Interior/Exterior",
                price: 269.99,
                oldPrice: 0,
                ribbon: "POPULAR",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 30.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior & Full Exterior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Exterior and Interior - Car Interior/Exterior",
                price: 239.99,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 30.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Full Interior & Light Exterior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Light Exterior - Car Interior/Exterior",
                price: 169.99,
                oldPrice: 0,
                ribbon: "POPULAR",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 30.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 3,
                serviceDisplayName: "Interior With Steam & Light Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div
                        style={{
                          color: "red",
                        }}
                      >
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Express Add on - Car Interior/Exterior",
                price: 150.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Polish",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/12/car-detailing-5.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 15.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg", // Assuming this is what you meant by +$ 140.00 CAD
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            categoryDisplayName: "Interior Detailing",
            type: "interiorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/Auto-Detailing.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailing",
                price: 140.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Wash +Tyres & Rims Shine",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/05/detailing-21.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wash Wax, Buff + Tyres & Rims",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/BUFFING-scaled-1.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 110.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 30.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 9,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 10,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName:
                  "Interior Detailing with Steam (No Shampoo)",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div
                        style={{
                          color: "red",
                        }}
                      >
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Interior Detailing with Steam (No Shampoo)",
                price: 120.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Wash +Tyres & Rims Shine",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/05/detailing-21.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wash Wax, Buff + Tyres & Rims",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/BUFFING-scaled-1.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 110.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 30.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 9,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 10,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 4,
            categoryDisplayName: "Exterior Detailing",
            type: "exteriorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/02/car-detailing-services-in-Coquitlam.jpg",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Exterior Car With Clay Bar",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR WITH CLAY BAR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Remove Tar, Tree Sap, Bug Splatter etc.
                          </strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Window & Windshield Clean From Outside
                          </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <strong>Please Note:</strong> This is Hand Wash, Wax Buff
                      With Machine and Tire/Rim Clean Shine (Not Cut and
                      Polish).
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Exterior: Hand Wash, Clay Bar and Machine Wax Polish, Wheel Clean & Tire Shine.",
                price: 140.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Interior Vacuum & Wipe Down",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2022/03/When-should-you-detail-your-car-boost-mobile-detailing.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Basic Package",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            color: "red",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Remove Tar, Tree Sap, Bug Splatter etc.
                          </strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Window & Windshield Clean From Outside
                          </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <strong>Please Note:</strong> This is Hand Wash, Wax Buff
                      With Machine and Tire/Rim Clean Shine (Not Cut and
                      Polish).
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Basic Package: Hand Wash, Rim and Wheel Cleaning, Wax Buff, Tire Dressing, Door Jambs clean, Window and Windshield Clean.",
                price: 110.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Interior Vacuum & Wipe Down",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2022/03/When-should-you-detail-your-car-boost-mobile-detailing.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Clay Bar",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/clay-bar.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      //SUV-5 Seats
      {
        id: 1,
        staffDisplayName: "SUV-5 Seats",
        type: "suv5",
        isAvailable: true,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719277699/suv_5_seater_white-removebg-preview_ilib0i.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "Ceramic Coating",
            type: "ceramicCoating",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/01/testimonial-detailing.jpg",
            ribbon: "POPULAR",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Diamond",
                serviceDiscription: (
                  <>
                    <h1>Diamond (Lifetime Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Engine Bay Detail</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>4 Coats of Ceramic Pro 9H on the Paint</li>
                      <li>
                        1 Coat of Ceramic Pro Top Coat on the Paint - Glass
                        Coating
                      </li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Diamond (Lifetime Warranty)",
                price: 1250.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 225.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Gold",
                serviceDiscription: (
                  <>
                    <h1>Gold (5 Years Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>2 Coats of Ceramic Pro 9H on the Paint</li>
                      <li>
                        1 Coat of Ceramic Pro Top Coat on the Paint - Glass
                        Coating
                      </li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Gold (5 Years Warranty)",
                price: 950.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 225.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Silver",
                serviceDiscription: (
                  <>
                    <h1>Silver (2 Years Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>2 Coats of Ceramic Coating on the Paint</li>
                      <li>Glass Coating</li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Silver (2Years Warranty)",
                price: 800.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 225.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 1,
            categoryDisplayName: "Paint Correction",
            type: "paintCorrection",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/paint-11-scaled.jpg",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Level 2",
                serviceDiscription: (
                  <>
                    <h1>Level 2 Paint Correction</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                    </ul>
                    <strong>CORRECTION</strong>
                    <p>
                      <strong>Duration:</strong> 2 to 3 Hours spent on enhancing
                      the paintwork
                    </p>
                    <p>
                      <strong>Action:</strong> Removes Light to Medium Scratches
                      and Swirl Marks
                    </p>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Rewash to remove any dust or compound</li>
                      <li>Light coat of Wax</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This process will not remove deep
                      Scratches that are beyond the clear coat.
                    </p>
                  </>
                ),
                serviceShortDiscription: "LEVEL 2 PAINT CORRECTION PREPARATION",
                price: 650.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 1,
                serviceDisplayName: "Level 3",
                serviceDiscription: (
                  <>
                    <h1>LEVEL 3 PAINT CORRECTION</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                    </ul>
                    <strong>CORRECTION</strong>
                    <p>
                      <strong>Duration:</strong> 4 to 6 Hours spent on enhancing
                      the paintwork
                    </p>
                    <p>
                      <strong>Action:</strong> Removes Medium to Heavy Scratches
                      and Swirl Marks, Oxidation
                    </p>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Rewash to remove any dust or compound</li>
                      <li>Light coat of Wax</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This process will not remove deep
                      Scratches that are beyond the clear coat.
                    </p>
                  </>
                ),
                serviceShortDiscription: "LEVEL 3 PAINT CORRECTION PREPARATION",
                price: 750.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
            ],
          },
          {
            id: 2,
            categoryDisplayName: "Interior & Exterior Detailing",
            type: "interiorAndExteriorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/06/Car-Detailing-in-Maple-Ridge-2.jpg",
            ribbon: "POPULAR",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "A to Z Auto Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "A to Z Auto Detailing - Car Interior/Exterior",
                price: 329.99,
                oldPrice: 0,
                ribbon: "POPULAR",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior & Full Exterior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Exterior and Interior - Car Interior/Exterior",
                price: 279.99,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Full Interior & Light Exterior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Light Exterior - Car Interior/Exterior",
                price: 199.99,
                oldPrice: 0,
                ribbon: "POPULAR",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 3,
                serviceDisplayName: "Interior With Steam & Light Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div
                        style={{
                          color: "red",
                        }}
                      >
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Express Add on - Car Interior/Exterior",
                price: 160.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Polish",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/12/car-detailing-5.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 15.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            categoryDisplayName: "Interior Detailing",
            type: "interiorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/Auto-Detailing.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailing",
                serviceDiscription: (
                  <>
                    <strong>Full Interior</strong>
                    <ul>
                      <li>• Vacuuming (Including Trunk)</li>
                      <li>• Shampooing all Carpets, Floor</li>
                      <li>• Mats, Seats. ( Not Includes Roof, Trunk)</li>
                      <li>• Steam Cleaning Dash, Center Console, Cup Holder</li>
                      <li>
                        • All Door, Light Door Jambs and Brushing on dirty
                        parts.
                      </li>
                      <li>
                        • Polishing Treatment to All Hard Interior Surfaces.
                      </li>
                      <li>• Leather Conditioning.</li>
                    </ul>
                    <strong>Windows and Windshield Clean</strong>
                    <ul>
                      <li>
                        • Streak Free Glass Cleaner(Tint Safe) from Inside
                      </li>
                    </ul>
                    <p>
                      <strong>Please Note:</strong> Additional charges if:- Pet
                      Hair / Mold / Commercial Dirty Vehicles Etc.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailing",
                price: 160.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Wash +Tyres & Rims Shine",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/05/detailing-21.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wash Wax, Buff + Tyres & Rims",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/BUFFING-scaled-1.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 130.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 9,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 10,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName:
                  "Interior Detailing with Steam (No Shampoo)",
                serviceDiscription: (
                  <>
                    <strong>Interior Detailing with Steam (No Shampoo)</strong>
                    <ul>
                      <li>• Vacuuming (Including Trunk)</li>
                      <li>• Steam Cleaning Dash, Center Console, Cup Holder</li>
                      <li>• Mats, Seats. ( Not Includes Roof, Trunk)</li>
                      <li>
                        • All Door,Light Door Jambs (Include Seat & Carpet)
                      </li>
                      <li>• Brushing on dirty parts.</li>
                      <li>
                        • Polishing Treatment to All Hard Interior Surfaces.
                      </li>
                      <li>• Leather Conditioning.</li>
                    </ul>
                    <strong>Windows and Windshield Clean</strong>
                    <ul>
                      <li>
                        • Streak Free Glass Cleaner(Tint Safe) from Inside
                      </li>
                    </ul>
                    <p>
                      <strong>Please Note:</strong> Additional charges if:- Pet
                      Hair / Mold / Commercial Dirty Vehicles Etc.
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Interior Detailing with Steam (No Shampoo)",
                price: 140.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Wash +Tyres & Rims Shine",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/05/detailing-21.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wash Wax, Buff + Tyres & Rims",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/BUFFING-scaled-1.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 130.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 9,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 10,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 4,
            categoryDisplayName: "Exterior Detailing",
            type: "exteriorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/02/car-detailing-services-in-Coquitlam.jpg",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Exterior car with clay bar",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR WITH CLAY BAR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Remove Tar, Tree Sap, Bug Splatter etc.
                          </strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Window & Windshield Clean From Outside
                          </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <strong>Please Note:</strong> This is Hand Wash, Wax Buff
                      With Machine and Tire/Rim Clean Shine (Not Cut and
                      Polish).
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Exterior: Hand Wash, Clay Bar and Machine Wax Polish, Wheel Clean & Tire Shine.",
                price: 160.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Interior Vacuum & Wipe Down",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2022/03/When-should-you-detail-your-car-boost-mobile-detailing.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Basic Package",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            color: "red",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Remove Tar, Tree Sap, Bug Splatter etc.
                          </strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Window & Windshield Clean From Outside
                          </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <strong>Please Note:</strong> This is Hand Wash, Wax Buff
                      With Machine and Tire/Rim Clean Shine (Not Cut and
                      Polish).
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Basic Package: Hand Wash, Rim and Wheel Cleaning, Wax Buff, Tire Dressing, Door Jambs clean, Window and Windshield Clean.",
                price: 130.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Interior Vacuum & Wipe Down",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2022/03/When-should-you-detail-your-car-boost-mobile-detailing.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Clay Bar",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/clay-bar.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      //Pickup Truck
      {
        id: 2,
        staffDisplayName: "Pickup Truck",
        type: "pickupTruck",
        isAvailable: true,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719277817/Puckup_Truck-removebg-preview_vlwsyj.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "Ceramic Coating",
            type: "ceramicCoating",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/01/testimonial-detailing.jpg",
            ribbon: "POPULAR",
            description: "coating",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Diamond",
                serviceDiscription: (
                  <>
                    <h1>Diamond (Lifetime Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Engine Bay Detail</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>4 Coats of Ceramic Pro 9H on the Paint</li>
                      <li>
                        1 Coat of Ceramic Pro Top Coat on the Paint - Glass
                        Coating
                      </li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Diamond (Lifetime Warranty)",
                price: 1500.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 300.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 600.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Gold",
                serviceDiscription: (
                  <>
                    <h1>Gold (5 Years Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>2 Coats of Ceramic Pro 9H on the Paint</li>
                      <li>
                        1 Coat of Ceramic Pro Top Coat on the Paint - Glass
                        Coating
                      </li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Gold (5 Years Warranty)",
                price: 1150.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 300.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 600.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Silver",
                serviceDiscription: (
                  <>
                    <h1>Silver (2 Years Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>2 Coats of Ceramic Coating on the Paint</li>
                      <li>Glass Coating</li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Silver (2Years Warranty)",
                price: 1000.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 300.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 600.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 1,
            categoryDisplayName: "Paint Correction",
            type: "paintCorrection",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/paint-11-scaled.jpg",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Level 2",
                serviceDiscription: (
                  <>
                    <h1>Level 2 Paint Correction</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                    </ul>
                    <strong>CORRECTION</strong>
                    <p>
                      <strong>Duration:</strong> 2 to 3 Hours spent on enhancing
                      the paintwork
                    </p>
                    <p>
                      <strong>Action:</strong> Removes Light to Medium Scratches
                      and Swirl Marks
                    </p>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Rewash to remove any dust or compound</li>
                      <li>Light coat of Wax</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This process will not remove deep
                      Scratches that are beyond the clear coat.
                    </p>
                  </>
                ),
                serviceShortDiscription: "LEVEL 2 PAINT CORRECTION PREPARATION",
                price: 800.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 1,
                serviceDisplayName: "Level 3",
                serviceDiscription: (
                  <>
                    <h1>LEVEL 3 PAINT CORRECTION</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                    </ul>
                    <strong>CORRECTION</strong>
                    <p>
                      <strong>Duration:</strong> 4 to 6 Hours spent on enhancing
                      the paintwork
                    </p>
                    <p>
                      <strong>Action:</strong> Removes Medium to Heavy Scratches
                      and Swirl Marks, Oxidation
                    </p>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Rewash to remove any dust or compound</li>
                      <li>Light coat of Wax</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This process will not remove deep
                      Scratches that are beyond the clear coat.
                    </p>
                  </>
                ),
                serviceShortDiscription: "LEVEL 3 PAINT CORRECTION PREPARATION",
                price: 900.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
            ],
          },
          {
            id: 2,
            categoryDisplayName: "Interior & Exterior Detailing",
            type: "interiorAndExteriorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/06/Car-Detailing-in-Maple-Ridge-2.jpg",
            ribbon: "POPULAR",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "A to Z Auto Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "A to Z Auto Detailing - Pickup Interior/Exterior",
                price: 369.99,
                oldPrice: 0,
                ribbon: "POPULAR",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior & Full Exterior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Exterior and Interior - Pickup Interior/Exterior",
                price: 289.99,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 3,
                serviceDisplayName: "Full Interior & Light Exterior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Light Exterior - Pickup Interior/Exterior",
                price: 199.99,
                oldPrice: 0,
                ribbon: "POPULAR",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 4,
                serviceDisplayName: "Interior With Steam & Light Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div
                        style={{
                          color: "red",
                        }}
                      >
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Express Add on - Pickup Interior/Exterior",
                price: 170.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Polish",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/12/car-detailing-5.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            categoryDisplayName: "Interior Detailing",
            type: "interiorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/Auto-Detailing.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailing",
                serviceDiscription: (
                  <>
                    <strong>Full Interior</strong>
                    <ul>
                      <li>• Vacuuming (Including Trunk)</li>
                      <li>• Shampooing all Carpets, Floor</li>
                      <li>• Mats, Seats. ( Not Includes Roof, Trunk)</li>
                      <li>• Steam Cleaning Dash, Center Console, Cup Holder</li>
                      <li>
                        • All Door, Light Door Jambs and Brushing on dirty
                        parts.
                      </li>
                      <li>
                        • Polishing Treatment to All Hard Interior Surfaces.
                      </li>
                      <li>• Leather Conditioning.</li>
                    </ul>
                    <strong>Windows and Windshield Clean</strong>
                    <ul>
                      <li>
                        • Streak Free Glass Cleaner(Tint Safe) from Inside
                      </li>
                    </ul>
                    <p>
                      <strong>Please Note:</strong> Additional charges if:- Pet
                      Hair / Mold / Commercial Dirty Vehicles Etc.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailing",
                price: 160.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Wash +Tyres & Rims Shine",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/05/detailing-21.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wash Wax, Buff + Tyres & Rims",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/BUFFING-scaled-1.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 160.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 9,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 10,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName:
                  "Interior Detailing with Steam (No Shampoo)",
                serviceDiscription: (
                  <>
                    <strong>Interior Detailing with Steam (No Shampoo)</strong>
                    <ul>
                      <li>• Vacuuming (Including Trunk)</li>
                      <li>• Steam Cleaning Dash, Center Console, Cup Holder</li>
                      <li>• Mats, Seats. ( Not Includes Roof, Trunk)</li>
                      <li>
                        • All Door,Light Door Jambs (Include Seat & Carpet)
                      </li>
                      <li>• Brushing on dirty parts.</li>
                      <li>
                        • Polishing Treatment to All Hard Interior Surfaces.
                      </li>
                      <li>• Leather Conditioning.</li>
                    </ul>
                    <strong>Windows and Windshield Clean</strong>
                    <ul>
                      <li>
                        • Streak Free Glass Cleaner(Tint Safe) from Inside
                      </li>
                    </ul>
                    <p>
                      <strong>Please Note:</strong> Additional charges if:- Pet
                      Hair / Mold / Commercial Dirty Vehicles Etc.
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Interior Detailing with Steam (No Shampoo)",
                price: 140.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Wash +Tyres & Rims Shine",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/05/detailing-21.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wash Wax, Buff + Tyres & Rims",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/BUFFING-scaled-1.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 9,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 10,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 4,
            categoryDisplayName: "Exterior Detailing",
            type: "exteriorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/02/car-detailing-services-in-Coquitlam.jpg",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Exterior car with clay bar",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR WITH CLAY BAR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Remove Tar, Tree Sap, Bug Splatter etc.
                          </strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Window & Windshield Clean From Outside
                          </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <strong>Please Note:</strong> This is Hand Wash, Wax Buff
                      With Machine and Tire/Rim Clean Shine (Not Cut and
                      Polish).
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Exterior: Hand Wash, Clay Bar and Machine Wax Polish, Wheel Clean & Tire Shine.",
                price: 200.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Interior Vacuum & Wipe Down",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2022/03/When-should-you-detail-your-car-boost-mobile-detailing.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Basic Package",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            color: "red",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Remove Tar, Tree Sap, Bug Splatter etc.
                          </strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Window & Windshield Clean From Outside
                          </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <strong>Please Note:</strong> This is Hand Wash, Wax Buff
                      With Machine and Tire/Rim Clean Shine (Not Cut and
                      Polish).
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Basic Package: Hand Wash, Rim and Wheel Cleaning, Wax Buff, Tire Dressing, Door Jambs clean, Window and Windshield Clean.",
                price: 150.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Interior Vacuum & Wipe Down",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2022/03/When-should-you-detail-your-car-boost-mobile-detailing.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 65.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Clay Bar",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/clay-bar.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 85.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 65.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      //SUV-7 Seats
      {
        id: 3,
        staffDisplayName: "SUV-7 Seats",
        type: "suv7",
        isAvailable: true,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719277862/suv_7_seater-removebg-preview_moe1tc.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "Ceramic Coating",
            type: "ceramicCoating",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/01/testimonial-detailing.jpg",
            ribbon: "POPULAR",
            description: "coating",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Diamond",
                serviceDiscription: (
                  <>
                    <h1>Diamond (Lifetime Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Engine Bay Detail</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>4 Coats of Ceramic Pro 9H on the Paint</li>
                      <li>
                        1 Coat of Ceramic Pro Top Coat on the Paint - Glass
                        Coating
                      </li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Diamond (Lifetime Warranty)",
                price: 1500.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 300.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 600.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Gold",
                serviceDiscription: (
                  <>
                    <h1>Gold (5 Years Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>2 Coats of Ceramic Pro 9H on the Paint</li>
                      <li>
                        1 Coat of Ceramic Pro Top Coat on the Paint - Glass
                        Coating
                      </li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Gold (5 Years Warranty)",
                price: 1150.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 300.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 600.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Silver",
                serviceDiscription: (
                  <>
                    <h1>Silver (2 Years Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>2 Coats of Ceramic Coating on the Paint</li>
                      <li>Glass Coating</li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Silver (2Years Warranty)",
                price: 1000.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 300.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 600.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 1,
            categoryDisplayName: "Paint Correction",
            type: "paintCorrection",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/paint-11-scaled.jpg",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Level 2",
                serviceDiscription: (
                  <>
                    <h1>Level 2 Paint Correction</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                    </ul>
                    <strong>CORRECTION</strong>
                    <p>
                      <strong>Duration:</strong> 2 to 3 Hours spent on enhancing
                      the paintwork
                    </p>
                    <p>
                      <strong>Action:</strong> Removes Light to Medium Scratches
                      and Swirl Marks
                    </p>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Rewash to remove any dust or compound</li>
                      <li>Light coat of Wax</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This process will not remove deep
                      Scratches that are beyond the clear coat.
                    </p>
                  </>
                ),
                serviceShortDiscription: "LEVEL 2 PAINT CORRECTION PREPARATION",
                price: 800.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 1,
                serviceDisplayName: "Level 3",
                serviceDiscription: (
                  <>
                    <h1>LEVEL 3 PAINT CORRECTION</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                    </ul>
                    <strong>CORRECTION</strong>
                    <p>
                      <strong>Duration:</strong> 4 to 6 Hours spent on enhancing
                      the paintwork
                    </p>
                    <p>
                      <strong>Action:</strong> Removes Medium to Heavy Scratches
                      and Swirl Marks, Oxidation
                    </p>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Rewash to remove any dust or compound</li>
                      <li>Light coat of Wax</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This process will not remove deep
                      Scratches that are beyond the clear coat.
                    </p>
                  </>
                ),
                serviceShortDiscription: "LEVEL 3 PAINT CORRECTION PREPARATION",
                price: 900.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
            ],
          },
          {
            id: 2,
            categoryDisplayName: "Interior & Exterior Detailing",
            type: "interiorAndExteriorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/06/Car-Detailing-in-Maple-Ridge-2.jpg",
            ribbon: "POPULAR",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "A to Z Auto Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "A to Z Auto Detailing - SUV-7 Seats Interior/Exterior",
                price: 399.99,
                oldPrice: 0,
                ribbon: "POPULAR",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 65.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior & Full Exterior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Exterior and Interior - SUV-7 Seats Interior/Exterior",
                price: 309.99,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 65.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Full Interior & Light Exterior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Light Exterior - SUV-7 Seats Interior/Exterior",
                price: 219.99,
                oldPrice: 0,
                ribbon: "POPULAR",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 65.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 3,
                serviceDisplayName: "Interior With Steam & Light Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div
                        style={{
                          color: "red",
                        }}
                      >
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Express Add on - Pickup Truck Interior/Exterior",
                price: 170.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Polish",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/12/car-detailing-5.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg", // Assuming this is what you meant by +$ 140.00 CAD
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            categoryDisplayName: "Interior Detailing",
            type: "interiorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/Auto-Detailing.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailing",
                serviceDiscription: (
                  <>
                    <strong>Full Interior</strong>
                    <ul>
                      <li>• Vacuuming (Including Trunk)</li>
                      <li>• Shampooing all Carpets, Floor</li>
                      <li>• Mats, Seats. ( Not Includes Roof, Trunk)</li>
                      <li>• Steam Cleaning Dash, Center Console, Cup Holder</li>
                      <li>
                        • All Door, Light Door Jambs and Brushing on dirty
                        parts.
                      </li>
                      <li>
                        • Polishing Treatment to All Hard Interior Surfaces.
                      </li>
                      <li>• Leather Conditioning.</li>
                    </ul>
                    <strong>Windows and Windshield Clean</strong>
                    <ul>
                      <li>
                        • Streak Free Glass Cleaner(Tint Safe) from Inside
                      </li>
                    </ul>
                    <p>
                      <strong>Please Note:</strong> Additional charges if:- Pet
                      Hair / Mold / Commercial Dirty Vehicles Etc.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailing",
                price: 180.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Wash + Tyres & Rims Shine",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wash Wax, Buff + Tyres & Rims",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/BUFFING-scaled-1.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 65.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 9,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 10,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName:
                  "Interior Detailing with Steam (No Shampoo)",
                serviceDiscription: (
                  <>
                    <strong>Interior Detailing with Steam (No Shampoo)</strong>
                    <ul>
                      <li>• Vacuuming (Including Trunk)</li>
                      <li>• Steam Cleaning Dash, Center Console, Cup Holder</li>
                      <li>• Mats, Seats. ( Not Includes Roof, Trunk)</li>
                      <li>
                        • All Door,Light Door Jambs (Include Seat & Carpet)
                      </li>
                      <li>• Brushing on dirty parts.</li>
                      <li>
                        • Polishing Treatment to All Hard Interior Surfaces.
                      </li>
                      <li>• Leather Conditioning.</li>
                    </ul>
                    <strong>Windows and Windshield Clean</strong>
                    <ul>
                      <li>
                        • Streak Free Glass Cleaner(Tint Safe) from Inside
                      </li>
                    </ul>
                    <p>
                      <strong>Please Note:</strong> Additional charges if:- Pet
                      Hair / Mold / Commercial Dirty Vehicles Etc.
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Interior Detailing with Steam (No Shampoo)",
                price: 160.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Wash +Tyres & Rims Shine",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/05/detailing-21.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wash Wax, Buff + Tyres & Rims",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/BUFFING-scaled-1.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 65.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 9,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 10,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 4,
            categoryDisplayName: "Exterior Detailing",
            type: "exteriorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/02/car-detailing-services-in-Coquitlam.jpg",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Exterior car with clay bar",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR WITH CLAY BAR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Remove Tar, Tree Sap, Bug Splatter etc.
                          </strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Window & Windshield Clean From Outside
                          </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <strong>Please Note:</strong> This is Hand Wash, Wax Buff
                      With Machine and Tire/Rim Clean Shine (Not Cut and
                      Polish).
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Exterior: Hand Wash, Clay Bar and Machine Wax Polish, Wheel Clean & Tire Shine.",
                price: 200.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Interior Vacuum & Wipe Down",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2022/03/When-should-you-detail-your-car-boost-mobile-detailing.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Basic Package",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            color: "red",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Remove Tar, Tree Sap, Bug Splatter etc.
                          </strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Window & Windshield Clean From Outside
                          </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <strong>Please Note:</strong> This is Hand Wash, Wax Buff
                      With Machine and Tire/Rim Clean Shine (Not Cut and
                      Polish).
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Basic Package: Hand Wash, Rim and Wheel Cleaning, Wax Buff, Tire Dressing, Door Jambs clean, Window and Windshield Clean.",
                price: 150.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Interior Vacuum & Wipe Down",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2022/03/When-should-you-detail-your-car-boost-mobile-detailing.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Clay Bar",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/clay-bar.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 85.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      //Commercial Vehicles
      {
        id: 4,
        staffDisplayName: "Commercial",
        type: "commercialVehicle",
        isAvailable: true,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719277924/Commercial_Truck-removebg-preview_ulo3fz.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "Semi - Sleeper",
            type: "semiSleeper",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/semi-sleeper-e1724914832780.png",
            ribbon: "",
            description: "",
            isAvailable: false,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailng & Exterior Wash",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, Refrigerator</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Detailng & Exterior Wash",
                price: 280.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior Detailng",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, Refrigerator</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailng",
                price: 200.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 110.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 1,
            categoryDisplayName: "Semi - Day Van",
            type: "semiDayCab",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/Semi-Day-Cab.png",
            ribbon: "",
            description: "",
            isAvailable: false,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailng & Exterior Wash",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Detailng & Exterior Wash",
                price: 230.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior Detailng",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailng",
                price: 150.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 100.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            categoryDisplayName: "Dump Truck Van",
            type: "dumpTruckCab",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/Dump-Truck-Cab-.png",
            ribbon: "",
            description: "",
            isAvailable: false,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailng & Exterior Wash",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Detailng & Exterior Wash",
                price: 400.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior Detailng",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailng",
                price: 180.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 250.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            categoryDisplayName: "Sprinter Van",
            type: "sprinterVanCab",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/Sprinter-Van-Cab-2.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailng & Exterior Wash",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Detailng & Exterior Wash",
                price: 225.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior Detailng",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailng",
                price: 135.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 100.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 4,
            categoryDisplayName: "Commercial Van",
            type: "commercialVanCab",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/Commercial-Van-Cab.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailng & Exterior Wash",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Detailng & Exterior Wash",
                price: 225.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior Detailng",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailng",
                price: 135.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 100.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 5,
            categoryDisplayName: "Big Machines/Equipment",
            type: "bigMachines",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/BIG-MACHINE-EQUIPMENT.png",
            ribbon: "",
            description: "",
            isAvailable: false,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailng & Exterior Wash",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Exterior High Pressure Wash using PH Balanced Soaps
                          </strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Spot Paint Decontamination</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Remove Grease, Oil, heavy dirt etc.</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Detailng & Exterior Wash",
                price: 480.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior Detailng",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailng",
                price: 180.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Exterior High Pressure Wash using PH Balanced Soaps
                          </strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Spot Paint Decontamination</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Remove Grease, Oil, heavy dirt etc.</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 320.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      // Rv Motors
      {
        id: 5,
        staffDisplayName: "RVs Motorhomes",
        type: "rv",
        isAvailable: false,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719277995/RV_motorhomes-removebg-preview_y7xulx.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "RV Services",
            type: "rvServices",
            servCategoryImgUrl: "",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: <></>,
                serviceShortDiscription: "Exterior Wash",
                price: 7.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 1,
                serviceDisplayName: "Wash, Wax, Buff and Polish",
                serviceDiscription: <></>,
                serviceShortDiscription: "Wash, Wax, Buff and Polish",
                price: 14.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 2,
                serviceDisplayName:
                  "Paint Correction (Oxidation & Swirl Marks)",
                serviceDiscription: <></>,
                serviceShortDiscription:
                  "Paint Correction (Oxidation & Swirl Marks)",
                price: 20.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 3,
                serviceDisplayName: "Interior Steam Detail",
                serviceDiscription: <></>,
                serviceShortDiscription: "Interior Steam Detail",
                price: 16.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
            ],
          },
        ],
      },

      // Fire Truck
      {
        id: 6,
        staffDisplayName: "Fire Truck",
        type: "fireTruck",
        isAvailable: false,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719278070/White_Fire_Truck-removebg-preview_hausrv.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "Fire Truck Service",
            type: "truckServices",
            servCategoryImgUrl: "",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Fire Truck",
                serviceDiscription: <></>,
                serviceShortDiscription: "Fire Truck Work",
                price: 180.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
            ],
          },
        ],
      },

      // Passenger Bus
      {
        id: 7,
        staffDisplayName: "Passenger Bus",
        type: "passengerBus",
        isAvailable: false,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719278127/bus-removebg-preview-1_woaqq8.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "Bus Services",
            type: "busServices",
            servCategoryImgUrl: "",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName:
                  "Full Interior with steam includes shampo carpet and seats",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 25.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 1,
                serviceDisplayName: "Light interior with steam",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 15.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 2,
                serviceDisplayName: "Exterior wash , wax , Buff, polish",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 20.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 3,
                serviceDisplayName: "Exterior wash",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 10.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
            ],
          },
        ],
      },

      // Boats
      {
        id: 8,
        staffDisplayName: "Boats",
        type: "boat",
        isAvailable: false,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719278179/Yacht-removebg-preview_jbjuml.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "Boats Services",
            type: "boatServices",
            servCategoryImgUrl: "",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Exterior Hull Wash",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 8.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 1,
                serviceDisplayName: "Exterior Hull Wash, Wax, Buff, Polish",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 14.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 2,
                serviceDisplayName: "Exteriors - Remove Oxidation and Swirls",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 40.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 3,
                serviceDisplayName:
                  "Interior - Wash Steam & Clean Vinyl Surfaces (No carpets / Fabrics)",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 12.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
            ],
          },
        ],
      },

      // Helicopter
      {
        id: 9,
        staffDisplayName: "Helicopter",
        type: "helicopter",
        isAvailable: false,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719278231/White_Helicopter1-removebg_u3ffwu.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [],
      },

      // House
      {
        id: 10,
        staffDisplayName: "House",
        type: "house",
        isAvailable: false,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719278286/White_Small_House-removebg-preview_atpbam.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "House Wash Booking?",
            type: "houseServices",
            servCategoryImgUrl: "",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "House Wash",
                serviceDiscription: <></>,
                serviceShortDiscription: "House Wash",
                price: 0.5,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    // mobile
    id: 1,
    displayName: "Appointment Mobile",
    locationRole: "MOBILE",
    imgUrl: mobileLoacation,
    description: "At Client Side",
    ribbon: "10% discount",
    locationDiscount: 10,
    bookingSlots: [
      {
        id: 0,
        time: "11:00am-12:00pm",
        availableSlots: 3,
      },
      {
        id: 1,
        time: "12:00pm-01:00pm",
        availableSlots: 3,
      },
      {
        id: 2,
        time: "01:00pm-02:00pm",
        availableSlots: 3,
      },
      {
        id: 3,
        time: "01:00pm-02:00pm",
        availableSlots: 3,
      },
      {
        id: 4,
        time: "01:00pm-02:00pm",
        availableSlots: 3,
      },
    ],
    staff: [
      //Car/Sedan
      {
        id: 0,
        staffDisplayName: "Car/Sedan",
        type: "car",
        isAvailable: true,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719277375/car_sedan-removebg-preview_p9vpjb.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "Ceramic Coating",
            type: "ceramicCoating",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/01/testimonial-detailing.jpg",
            ribbon: "POPULAR",
            description: "coating",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Diamond",
                serviceDiscription: (
                  <>
                    <h1>Diamond (Lifetime Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Engine Bay Detail</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>4 Coats of Ceramic Pro 9H on the Paint</li>
                      <li>
                        1 Coat of Ceramic Pro Top Coat on the Paint - Glass
                        Coating
                      </li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Diamond (Lifetime Warranty)",
                price: 1000.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 400.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Gold",
                serviceDiscription: (
                  <>
                    <h1>Gold (5 Years Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>2 Coats of Ceramic Pro 9H on the Paint</li>
                      <li>
                        1 Coat of Ceramic Pro Top Coat on the Paint - Glass
                        Coating
                      </li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Gold (5 Years Warranty)",
                price: 750.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 400.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Silver",
                serviceDiscription: (
                  <>
                    <h1>Silver (2 Years Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>2 Coats of Ceramic Coating on the Paint</li>
                      <li>Glass Coating</li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Silver (2Years Warranty)",
                price: 600.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 400.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 1,
            categoryDisplayName: "Paint Correction",
            type: "paintCorrection",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/paint-11-scaled.jpg",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Level 2",
                serviceDiscription: (
                  <>
                    <h1>Level 2 Paint Correction</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                    </ul>
                    <strong>CORRECTION</strong>
                    <p>
                      <strong>Duration:</strong> 2 to 3 Hours spent on enhancing
                      the paintwork
                    </p>
                    <p>
                      <strong>Action:</strong> Removes Light to Medium Scratches
                      and Swirl Marks
                    </p>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Rewash to remove any dust or compound</li>
                      <li>Light coat of Wax</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This process will not remove deep
                      Scratches that are beyond the clear coat.
                    </p>
                  </>
                ),
                serviceShortDiscription: "LEVEL 2 PAINT CORRECTION PREPARATION",
                price: 500.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 1,
                serviceDisplayName: "Level 3",
                serviceDiscription: (
                  <>
                    <h1>LEVEL 3 PAINT CORRECTION</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                    </ul>
                    <strong>CORRECTION</strong>
                    <p>
                      <strong>Duration:</strong> 4 to 6 Hours spent on enhancing
                      the paintwork
                    </p>
                    <p>
                      <strong>Action:</strong> Removes Medium to Heavy Scratches
                      and Swirl Marks, Oxidation
                    </p>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Rewash to remove any dust or compound</li>
                      <li>Light coat of Wax</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This process will not remove deep
                      Scratches that are beyond the clear coat.
                    </p>
                  </>
                ),
                serviceShortDiscription: "LEVEL 3 PAINT CORRECTION PREPARATION",
                price: 600.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
            ],
          },
          {
            id: 2,
            categoryDisplayName: "Interior & Exterior Detailing",
            type: "interiorAndExteriorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/06/Car-Detailing-in-Maple-Ridge-2.jpg",
            ribbon: "POPULAR",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "A to Z Auto Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "A to Z Auto Detailing - Car Interior/Exterior",
                price: 269.99,
                oldPrice: 0,
                ribbon: "POPULAR",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 30.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior & Full Exterior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Exterior and Interior - Car Interior/Exterior",
                price: 239.99,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 30.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Full Interior & Light Exterior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Light Exterior - Car Interior/Exterior",
                price: 169.99,
                oldPrice: 0,
                ribbon: "POPULAR",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 30.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 3,
                serviceDisplayName: "Interior With Steam & Light Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div
                        style={{
                          color: "red",
                        }}
                      >
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Express Add on - Car Interior/Exterior",
                price: 150.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Polish",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/12/car-detailing-5.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 15.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg", // Assuming this is what you meant by +$ 140.00 CAD
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            categoryDisplayName: "Interior Detailing",
            type: "interiorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/Auto-Detailing.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailing",
                serviceDiscription: (
                  <>
                    <strong>Full Interior</strong>
                    <ul>
                      <li>• Vacuuming (Including Trunk)</li>
                      <li>• Shampooing all Carpets, Floor</li>
                      <li>• Mats, Seats. ( Not Includes Roof, Trunk)</li>
                      <li>• Steam Cleaning Dash, Center Console, Cup Holder</li>
                      <li>
                        • All Door, Light Door Jambs and Brushing on dirty
                        parts.
                      </li>
                      <li>
                        • Polishing Treatment to All Hard Interior Surfaces.
                      </li>
                      <li>• Leather Conditioning.</li>
                    </ul>
                    <strong>Windows and Windshield Clean</strong>
                    <ul>
                      <li>
                        • Streak Free Glass Cleaner(Tint Safe) from Inside
                      </li>
                    </ul>
                    <p>
                      <strong>Please Note:</strong> Additional charges if:- Pet
                      Hair / Mold / Commercial Dirty Vehicles Etc.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailing",
                price: 140.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Wash +Tyres & Rims Shine",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/05/detailing-21.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wash Wax, Buff + Tyres & Rims",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/BUFFING-scaled-1.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 110.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 30.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 9,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 10,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName:
                  "Interior Detailing with Steam (No Shampoo)",
                serviceDiscription: (
                  <>
                    <strong>Interior Detailing with Steam (No Shampoo)</strong>
                    <ul>
                      <li>• Vacuuming (Including Trunk)</li>
                      <li>• Steam Cleaning Dash, Center Console, Cup Holder</li>
                      <li>• Mats, Seats. ( Not Includes Roof, Trunk)</li>
                      <li>
                        • All Door,Light Door Jambs (Include Seat & Carpet)
                      </li>
                      <li>• Brushing on dirty parts.</li>
                      <li>
                        • Polishing Treatment to All Hard Interior Surfaces.
                      </li>
                      <li>• Leather Conditioning.</li>
                    </ul>
                    <strong>Windows and Windshield Clean</strong>
                    <ul>
                      <li>
                        • Streak Free Glass Cleaner(Tint Safe) from Inside
                      </li>
                    </ul>
                    <p>
                      <strong>Please Note:</strong> Additional charges if:- Pet
                      Hair / Mold / Commercial Dirty Vehicles Etc.
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Interior Detailing with Steam (No Shampoo)",
                price: 120.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Wash +Tyres & Rims Shine",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/05/detailing-21.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wash Wax, Buff + Tyres & Rims",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/BUFFING-scaled-1.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 110.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 30.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 9,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 10,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 4,
            categoryDisplayName: "Exterior Detailing",
            type: "exteriorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/02/car-detailing-services-in-Coquitlam.jpg",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Exterior car with clay bar",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR WITH CLAY BAR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Remove Tar, Tree Sap, Bug Splatter etc.
                          </strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Window & Windshield Clean From Outside
                          </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <strong>Please Note:</strong> This is Hand Wash, Wax Buff
                      With Machine and Tire/Rim Clean Shine (Not Cut and
                      Polish).
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Exterior: Hand Wash, Clay Bar and Machine Wax Polish, Wheel Clean & Tire Shine.",
                price: 140.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Interior Vacuum & Wipe Down",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/05/5-1-e1622250307488.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Basic Package",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            color: "red",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Remove Tar, Tree Sap, Bug Splatter etc.
                          </strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Window & Windshield Clean From Outside
                          </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <strong>Please Note:</strong> This is Hand Wash, Wax Buff
                      With Machine and Tire/Rim Clean Shine (Not Cut and
                      Polish).
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Basic Package: Hand Wash, Rim and Wheel Cleaning, Wax Buff, Tire Dressing, Door Jambs clean, Window and Windshield Clean.",
                price: 110.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Interior Vacuum & Wipe Down",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2022/03/When-should-you-detail-your-car-boost-mobile-detailing.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Clay Bar",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/clay-bar.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      //SUV-5 Seats
      {
        id: 1,
        staffDisplayName: "SUV-5 Seats",
        type: "suv5",
        isAvailable: true,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719277699/suv_5_seater_white-removebg-preview_ilib0i.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "Ceramic Coating",
            type: "ceramicCoating",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/01/testimonial-detailing.jpg",
            ribbon: "POPULAR",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Diamond",
                serviceDiscription: (
                  <>
                    <h1>Diamond (Lifetime Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Engine Bay Detail</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>4 Coats of Ceramic Pro 9H on the Paint</li>
                      <li>
                        1 Coat of Ceramic Pro Top Coat on the Paint - Glass
                        Coating
                      </li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Diamond (Lifetime Warranty)",
                price: 1250.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 225.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Gold",
                serviceDiscription: (
                  <>
                    <h1>Gold (5 Years Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>2 Coats of Ceramic Pro 9H on the Paint</li>
                      <li>
                        1 Coat of Ceramic Pro Top Coat on the Paint - Glass
                        Coating
                      </li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Gold (5 Years Warranty)",
                price: 950.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 225.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Silver",
                serviceDiscription: (
                  <>
                    <h1>Silver (2 Years Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>2 Coats of Ceramic Coating on the Paint</li>
                      <li>Glass Coating</li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Silver (2Years Warranty)",
                price: 800.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 225.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 1,
            categoryDisplayName: "Paint Correction",
            type: "paintCorrection",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/paint-11-scaled.jpg",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Level 2",
                serviceDiscription: (
                  <>
                    <h1>Level 2 Paint Correction</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                    </ul>
                    <strong>CORRECTION</strong>
                    <p>
                      <strong>Duration:</strong> 2 to 3 Hours spent on enhancing
                      the paintwork
                    </p>
                    <p>
                      <strong>Action:</strong> Removes Light to Medium Scratches
                      and Swirl Marks
                    </p>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Rewash to remove any dust or compound</li>
                      <li>Light coat of Wax</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This process will not remove deep
                      Scratches that are beyond the clear coat.
                    </p>
                  </>
                ),
                serviceShortDiscription: "LEVEL 2 PAINT CORRECTION PREPARATION",
                price: 650.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 1,
                serviceDisplayName: "Level 3",
                serviceDiscription: (
                  <>
                    <h1>LEVEL 3 PAINT CORRECTION</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                    </ul>
                    <strong>CORRECTION</strong>
                    <p>
                      <strong>Duration:</strong> 4 to 6 Hours spent on enhancing
                      the paintwork
                    </p>
                    <p>
                      <strong>Action:</strong> Removes Medium to Heavy Scratches
                      and Swirl Marks, Oxidation
                    </p>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Rewash to remove any dust or compound</li>
                      <li>Light coat of Wax</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This process will not remove deep
                      Scratches that are beyond the clear coat.
                    </p>
                  </>
                ),
                serviceShortDiscription: "LEVEL 3 PAINT CORRECTION PREPARATION",
                price: 750.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
            ],
          },
          {
            id: 2,
            categoryDisplayName: "Interior & Exterior Detailing",
            type: "interiorAndExteriorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/06/Car-Detailing-in-Maple-Ridge-2.jpg",
            ribbon: "POPULAR",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "A to Z Auto Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "A to Z Auto Detailing - Car Interior/Exterior",
                price: 329.99,
                oldPrice: 0,
                ribbon: "POPULAR",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior & Full Exterior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Exterior and Interior - Car Interior/Exterior",
                price: 279.99,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Full Interior & Light Exterior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Light Exterior - Car Interior/Exterior",
                price: 199.99,
                oldPrice: 0,
                ribbon: "POPULAR",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 3,
                serviceDisplayName: "Interior With Steam & Light Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div
                        style={{
                          color: "red",
                        }}
                      >
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Express Add on - Car Interior/Exterior",
                price: 160.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Polish",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/12/car-detailing-5.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 15.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            categoryDisplayName: "Interior Detailing",
            type: "interiorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/Auto-Detailing.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailing",
                serviceDiscription: (
                  <>
                    <strong>Full Interior</strong>
                    <ul>
                      <li>• Vacuuming (Including Trunk)</li>
                      <li>• Shampooing all Carpets, Floor</li>
                      <li>• Mats, Seats. ( Not Includes Roof, Trunk)</li>
                      <li>• Steam Cleaning Dash, Center Console, Cup Holder</li>
                      <li>
                        • All Door, Light Door Jambs and Brushing on dirty
                        parts.
                      </li>
                      <li>
                        • Polishing Treatment to All Hard Interior Surfaces.
                      </li>
                      <li>• Leather Conditioning.</li>
                    </ul>
                    <strong>Windows and Windshield Clean</strong>
                    <ul>
                      <li>
                        • Streak Free Glass Cleaner(Tint Safe) from Inside
                      </li>
                    </ul>
                    <p>
                      <strong>Please Note:</strong> Additional charges if:- Pet
                      Hair / Mold / Commercial Dirty Vehicles Etc.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailing",
                price: 160.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Wash +Tyres & Rims Shine",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/05/detailing-21.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wash Wax, Buff + Tyres & Rims",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/BUFFING-scaled-1.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 130.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 9,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 10,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName:
                  "Interior Detailing with Steam (No Shampoo)",
                serviceDiscription: (
                  <>
                    <strong>Interior Detailing with Steam (No Shampoo)</strong>
                    <ul>
                      <li>• Vacuuming (Including Trunk)</li>
                      <li>• Steam Cleaning Dash, Center Console, Cup Holder</li>
                      <li>• Mats, Seats. ( Not Includes Roof, Trunk)</li>
                      <li>
                        • All Door,Light Door Jambs (Include Seat & Carpet)
                      </li>
                      <li>• Brushing on dirty parts.</li>
                      <li>
                        • Polishing Treatment to All Hard Interior Surfaces.
                      </li>
                      <li>• Leather Conditioning.</li>
                    </ul>
                    <strong>Windows and Windshield Clean</strong>
                    <ul>
                      <li>
                        • Streak Free Glass Cleaner(Tint Safe) from Inside
                      </li>
                    </ul>
                    <p>
                      <strong>Please Note:</strong> Additional charges if:- Pet
                      Hair / Mold / Commercial Dirty Vehicles Etc.
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Interior Detailing with Steam (No Shampoo)",
                price: 140.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Wash +Tyres & Rims Shine",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/05/detailing-21.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wash Wax, Buff + Tyres & Rims",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/BUFFING-scaled-1.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 130.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 9,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 10,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 4,
            categoryDisplayName: "Exterior Detailing",
            type: "exteriorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/02/car-detailing-services-in-Coquitlam.jpg",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Exterior car with clay bar",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR WITH CLAY BAR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Remove Tar, Tree Sap, Bug Splatter etc.
                          </strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Window & Windshield Clean From Outside
                          </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <strong>Please Note:</strong> This is Hand Wash, Wax Buff
                      With Machine and Tire/Rim Clean Shine (Not Cut and
                      Polish).
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Exterior: Hand Wash, Clay Bar and Machine Wax Polish, Wheel Clean & Tire Shine.",
                price: 160.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Interior Vacuum & Wipe Down",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2022/03/When-should-you-detail-your-car-boost-mobile-detailing.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Basic Package",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            color: "red",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Remove Tar, Tree Sap, Bug Splatter etc.
                          </strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Window & Windshield Clean From Outside
                          </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <strong>Please Note:</strong> This is Hand Wash, Wax Buff
                      With Machine and Tire/Rim Clean Shine (Not Cut and
                      Polish).
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Basic Package: Hand Wash, Rim and Wheel Cleaning, Wax Buff, Tire Dressing, Door Jambs clean, Window and Windshield Clean.",
                price: 130.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Interior Vacuum & Wipe Down",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2022/03/When-should-you-detail-your-car-boost-mobile-detailing.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Clay Bar",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/clay-bar.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      //Pickup Truck
      {
        id: 2,
        staffDisplayName: "Pickup Truck",
        type: "pickupTruck",
        isAvailable: true,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719277817/Puckup_Truck-removebg-preview_vlwsyj.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "Ceramic Coating",
            type: "ceramicCoating",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/01/testimonial-detailing.jpg",
            ribbon: "POPULAR",
            description: "coating",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Diamond",
                serviceDiscription: (
                  <>
                    <h1>Diamond (Lifetime Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Engine Bay Detail</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>4 Coats of Ceramic Pro 9H on the Paint</li>
                      <li>
                        1 Coat of Ceramic Pro Top Coat on the Paint - Glass
                        Coating
                      </li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Diamond (Lifetime Warranty)",
                price: 1500.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 300.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 600.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Gold",
                serviceDiscription: (
                  <>
                    <h1>Gold (5 Years Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>2 Coats of Ceramic Pro 9H on the Paint</li>
                      <li>
                        1 Coat of Ceramic Pro Top Coat on the Paint - Glass
                        Coating
                      </li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Gold (5 Years Warranty)",
                price: 1150.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 300.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 600.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Silver",
                serviceDiscription: (
                  <>
                    <h1>Silver (2 Years Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>2 Coats of Ceramic Coating on the Paint</li>
                      <li>Glass Coating</li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Silver (2Years Warranty)",
                price: 1000.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 300.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 600.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 1,
            categoryDisplayName: "Paint Correction",
            type: "paintCorrection",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/paint-11-scaled.jpg",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Level 2",
                serviceDiscription: (
                  <>
                    <h1>Level 2 Paint Correction</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                    </ul>
                    <strong>CORRECTION</strong>
                    <p>
                      <strong>Duration:</strong> 2 to 3 Hours spent on enhancing
                      the paintwork
                    </p>
                    <p>
                      <strong>Action:</strong> Removes Light to Medium Scratches
                      and Swirl Marks
                    </p>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Rewash to remove any dust or compound</li>
                      <li>Light coat of Wax</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This process will not remove deep
                      Scratches that are beyond the clear coat.
                    </p>
                  </>
                ),
                serviceShortDiscription: "LEVEL 2 PAINT CORRECTION PREPARATION",
                price: 800.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 1,
                serviceDisplayName: "Level 3",
                serviceDiscription: (
                  <>
                    <h1>LEVEL 3 PAINT CORRECTION</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                    </ul>
                    <strong>CORRECTION</strong>
                    <p>
                      <strong>Duration:</strong> 4 to 6 Hours spent on enhancing
                      the paintwork
                    </p>
                    <p>
                      <strong>Action:</strong> Removes Medium to Heavy Scratches
                      and Swirl Marks, Oxidation
                    </p>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Rewash to remove any dust or compound</li>
                      <li>Light coat of Wax</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This process will not remove deep
                      Scratches that are beyond the clear coat.
                    </p>
                  </>
                ),
                serviceShortDiscription: "LEVEL 3 PAINT CORRECTION PREPARATION",
                price: 900.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
            ],
          },
          {
            id: 2,
            categoryDisplayName: "Interior & Exterior Detailing",
            type: "interiorAndExteriorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/06/Car-Detailing-in-Maple-Ridge-2.jpg",
            ribbon: "POPULAR",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "A to Z Auto Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "A to Z Auto Detailing - Pickup Interior/Exterior",
                price: 369.99,
                oldPrice: 0,
                ribbon: "POPULAR",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior & Full Exterior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Exterior and Interior - Pickup Interior/Exterior",
                price: 289.99,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Full Interior & Light Exterior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Light Exterior - Pickup Interior/Exterior",
                price: 199.99,
                oldPrice: 0,
                ribbon: "POPULAR",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 3,
                serviceDisplayName: "Interior With Steam & Light Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div
                        style={{
                          color: "red",
                        }}
                      >
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Express Add on - Pickup Interior/Exterior",
                price: 170.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Polish",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/12/car-detailing-5.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            categoryDisplayName: "Interior Detailing",
            type: "interiorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/Auto-Detailing.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailing",
                serviceDiscription: (
                  <>
                    <strong>Full Interior</strong>
                    <ul>
                      <li>• Vacuuming (Including Trunk)</li>
                      <li>• Shampooing all Carpets, Floor</li>
                      <li>• Mats, Seats. ( Not Includes Roof, Trunk)</li>
                      <li>• Steam Cleaning Dash, Center Console, Cup Holder</li>
                      <li>
                        • All Door, Light Door Jambs and Brushing on dirty
                        parts.
                      </li>
                      <li>
                        • Polishing Treatment to All Hard Interior Surfaces.
                      </li>
                      <li>• Leather Conditioning.</li>
                    </ul>
                    <strong>Windows and Windshield Clean</strong>
                    <ul>
                      <li>
                        • Streak Free Glass Cleaner(Tint Safe) from Inside
                      </li>
                    </ul>
                    <p>
                      <strong>Please Note:</strong> Additional charges if:- Pet
                      Hair / Mold / Commercial Dirty Vehicles Etc.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailing",
                price: 160.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Wash +Tyres & Rims Shine",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/05/detailing-21.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wash Wax, Buff + Tyres & Rims",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/BUFFING-scaled-1.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 160.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 9,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 10,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName:
                  "Interior Detailing with Steam (No Shampoo)",
                serviceDiscription: (
                  <>
                    <strong>Interior Detailing with Steam (No Shampoo)</strong>
                    <ul>
                      <li>• Vacuuming (Including Trunk)</li>
                      <li>• Steam Cleaning Dash, Center Console, Cup Holder</li>
                      <li>• Mats, Seats. ( Not Includes Roof, Trunk)</li>
                      <li>
                        • All Door,Light Door Jambs (Include Seat & Carpet)
                      </li>
                      <li>• Brushing on dirty parts.</li>
                      <li>
                        • Polishing Treatment to All Hard Interior Surfaces.
                      </li>
                      <li>• Leather Conditioning.</li>
                    </ul>
                    <strong>Windows and Windshield Clean</strong>
                    <ul>
                      <li>
                        • Streak Free Glass Cleaner(Tint Safe) from Inside
                      </li>
                    </ul>
                    <p>
                      <strong>Please Note:</strong> Additional charges if:- Pet
                      Hair / Mold / Commercial Dirty Vehicles Etc.
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Interior Detailing with Steam (No Shampoo)",
                price: 140.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Wash +Tyres & Rims Shine",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/05/detailing-21.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wash Wax, Buff + Tyres & Rims",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/BUFFING-scaled-1.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 9,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 10,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 4,
            categoryDisplayName: "Exterior Detailing",
            type: "exteriorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/02/car-detailing-services-in-Coquitlam.jpg",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Exterior car with clay bar",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR WITH CLAY BAR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Remove Tar, Tree Sap, Bug Splatter etc.
                          </strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Window & Windshield Clean From Outside
                          </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <strong>Please Note:</strong> This is Hand Wash, Wax Buff
                      With Machine and Tire/Rim Clean Shine (Not Cut and
                      Polish).
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Exterior: Hand Wash, Clay Bar and Machine Wax Polish, Wheel Clean & Tire Shine.",
                price: 200.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Interior Vacuum & Wipe Down",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2022/03/When-should-you-detail-your-car-boost-mobile-detailing.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Basic Package",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            color: "red",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Remove Tar, Tree Sap, Bug Splatter etc.
                          </strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Window & Windshield Clean From Outside
                          </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <strong>Please Note:</strong> This is Hand Wash, Wax Buff
                      With Machine and Tire/Rim Clean Shine (Not Cut and
                      Polish).
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Basic Package: Hand Wash, Rim and Wheel Cleaning, Wax Buff, Tire Dressing, Door Jambs clean, Window and Windshield Clean.",
                price: 150.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Interior Vacuum & Wipe Down",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2022/03/When-should-you-detail-your-car-boost-mobile-detailing.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 65.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Clay Bar",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/clay-bar.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 85.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 65.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      //SUV-7 Seats
      {
        id: 3,
        staffDisplayName: "SUV-7 Seats",
        type: "suv7",
        isAvailable: true,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719277862/suv_7_seater-removebg-preview_moe1tc.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "Ceramic Coating",
            type: "ceramicCoating",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/01/testimonial-detailing.jpg",
            ribbon: "POPULAR",
            description: "coating",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Diamond",
                serviceDiscription: (
                  <>
                    <h1>Diamond (Lifetime Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Engine Bay Detail</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>4 Coats of Ceramic Pro 9H on the Paint</li>
                      <li>
                        1 Coat of Ceramic Pro Top Coat on the Paint - Glass
                        Coating
                      </li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Diamond (Lifetime Warranty)",
                price: 1500.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 300.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 600.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Gold",
                serviceDiscription: (
                  <>
                    <h1>Gold (5 Years Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>2 Coats of Ceramic Pro 9H on the Paint</li>
                      <li>
                        1 Coat of Ceramic Pro Top Coat on the Paint - Glass
                        Coating
                      </li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Gold (5 Years Warranty)",
                price: 1150.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 300.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 600.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Silver",
                serviceDiscription: (
                  <>
                    <h1>Silver (2 Years Warranty)</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                      <li>Minimum Level 2 Paint Correction</li>
                    </ul>
                    <strong>COATING</strong>
                    <ul>
                      <li>2 Coats of Ceramic Coating on the Paint</li>
                      <li>Glass Coating</li>
                    </ul>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Final Inspection</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This includes Level 2 Paint
                      Correction if you have Medium to Heavy Scratches and Swirl
                      Marks, Oxidation. Please select Level 3 Paint Corrections
                      in Optional Service.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Silver (2Years Warranty)",
                price: 1000.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Level 3 Paint Correction",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/two-stage-paint-correction-on-half-of-a-hood.jpg",
                    serviceShortDiscription: "",
                    price: 300.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wheel Coating",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/08/car-detailing-2.jpg",
                    serviceShortDiscription: "",
                    price: 500.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Interior Coating",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/interior-car-detailing.png",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 600.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 1,
            categoryDisplayName: "Paint Correction",
            type: "paintCorrection",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/paint-11-scaled.jpg",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Level 2",
                serviceDiscription: (
                  <>
                    <h1>Level 2 Paint Correction</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                    </ul>
                    <strong>CORRECTION</strong>
                    <p>
                      <strong>Duration:</strong> 2 to 3 Hours spent on enhancing
                      the paintwork
                    </p>
                    <p>
                      <strong>Action:</strong> Removes Light to Medium Scratches
                      and Swirl Marks
                    </p>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Rewash to remove any dust or compound</li>
                      <li>Light coat of Wax</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This process will not remove deep
                      Scratches that are beyond the clear coat.
                    </p>
                  </>
                ),
                serviceShortDiscription: "LEVEL 2 PAINT CORRECTION PREPARATION",
                price: 800.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 1,
                serviceDisplayName: "Level 3",
                serviceDiscription: (
                  <>
                    <h1>LEVEL 3 PAINT CORRECTION</h1>
                    <strong>PREPARATION</strong>
                    <ul>
                      <li>Wheel Detailing</li>
                      <li>Iron and Tar Removal</li>
                      <li>Clay Bar Treatment</li>
                    </ul>
                    <strong>CORRECTION</strong>
                    <p>
                      <strong>Duration:</strong> 4 to 6 Hours spent on enhancing
                      the paintwork
                    </p>
                    <p>
                      <strong>Action:</strong> Removes Medium to Heavy Scratches
                      and Swirl Marks, Oxidation
                    </p>
                    <strong>FINAL TOUCH</strong>
                    <ul>
                      <li>Rewash to remove any dust or compound</li>
                      <li>Light coat of Wax</li>
                      <li>Maintenance Interior Detail</li>
                    </ul>
                    <p>
                      <strong>Note:</strong> This process will not remove deep
                      Scratches that are beyond the clear coat.
                    </p>
                  </>
                ),
                serviceShortDiscription: "LEVEL 3 PAINT CORRECTION PREPARATION",
                price: 900.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
            ],
          },
          {
            id: 2,
            categoryDisplayName: "Interior & Exterior Detailing",
            type: "interiorAndExteriorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/06/Car-Detailing-in-Maple-Ridge-2.jpg",
            ribbon: "POPULAR",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "A to Z Auto Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "A to Z Auto Detailing - SUV-7 Seats Interior/Exterior",
                price: 399.99,
                oldPrice: 0,
                ribbon: "POPULAR",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 65.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior & Full Exterior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Exterior and Interior - SUV-7 Seats Interior/Exterior",
                price: 309.99,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 65.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Full Interior & Light Exterior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Light Exterior - SUV-7 Seats Interior/Exterior",
                price: 219.99,
                oldPrice: 0,
                ribbon: "POPULAR",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 65.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 3,
                serviceDisplayName: "Interior With Steam & Light Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div
                        style={{
                          color: "red",
                        }}
                      >
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Express Add on - Pickup Truck Interior/Exterior",
                price: 170.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Polish",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/12/car-detailing-5.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg", // Assuming this is what you meant by +$ 140.00 CAD
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            categoryDisplayName: "Interior Detailing",
            type: "interiorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2023/09/Auto-Detailing.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailing",
                serviceDiscription: (
                  <>
                    <strong>Full Interior</strong>
                    <ul>
                      <li>• Vacuuming (Including Trunk)</li>
                      <li>• Shampooing all Carpets, Floor</li>
                      <li>• Mats, Seats. ( Not Includes Roof, Trunk)</li>
                      <li>• Steam Cleaning Dash, Center Console, Cup Holder</li>
                      <li>
                        • All Door, Light Door Jambs and Brushing on dirty
                        parts.
                      </li>
                      <li>
                        • Polishing Treatment to All Hard Interior Surfaces.
                      </li>
                      <li>• Leather Conditioning.</li>
                    </ul>
                    <strong>Windows and Windshield Clean</strong>
                    <ul>
                      <li>
                        • Streak Free Glass Cleaner(Tint Safe) from Inside
                      </li>
                    </ul>
                    <p>
                      <strong>Please Note:</strong> Additional charges if:- Pet
                      Hair / Mold / Commercial Dirty Vehicles Etc.
                    </p>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailing",
                price: 180.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Wash + Tyres & Rims Shine",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wash Wax, Buff + Tyres & Rims",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/BUFFING-scaled-1.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 65.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 9,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 10,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName:
                  "Interior Detailing with Steam (No Shampoo)",
                serviceDiscription: (
                  <>
                    <strong>Interior Detailing with Steam (No Shampoo)</strong>
                    <ul>
                      <li>• Vacuuming (Including Trunk)</li>
                      <li>• Steam Cleaning Dash, Center Console, Cup Holder</li>
                      <li>• Mats, Seats. ( Not Includes Roof, Trunk)</li>
                      <li>
                        • All Door,Light Door Jambs (Include Seat & Carpet)
                      </li>
                      <li>• Brushing on dirty parts.</li>
                      <li>
                        • Polishing Treatment to All Hard Interior Surfaces.
                      </li>
                      <li>• Leather Conditioning.</li>
                    </ul>
                    <strong>Windows and Windshield Clean</strong>
                    <ul>
                      <li>
                        • Streak Free Glass Cleaner(Tint Safe) from Inside
                      </li>
                    </ul>
                    <p>
                      <strong>Please Note:</strong> Additional charges if:- Pet
                      Hair / Mold / Commercial Dirty Vehicles Etc.
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Interior Detailing with Steam (No Shampoo)",
                price: 160.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Exterior Wash +Tyres & Rims Shine",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/05/detailing-21.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Wash Wax, Buff + Tyres & Rims",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2023/04/BUFFING-scaled-1.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 150.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 65.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 45.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 8,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 9,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 10,
                    serviceDisplayName: "Kid Seat",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 4,
            categoryDisplayName: "Exterior Detailing",
            type: "exteriorDetaling",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/02/car-detailing-services-in-Coquitlam.jpg",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Exterior car with clay bar",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR WITH CLAY BAR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Remove Tar, Tree Sap, Bug Splatter etc.
                          </strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Window & Windshield Clean From Outside
                          </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <strong>Please Note:</strong> This is Hand Wash, Wax Buff
                      With Machine and Tire/Rim Clean Shine (Not Cut and
                      Polish).
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Exterior: Hand Wash, Clay Bar and Machine Wax Polish, Wheel Clean & Tire Shine.",
                price: 200.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Interior Vacuum & Wipe Down",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2022/03/When-should-you-detail-your-car-boost-mobile-detailing.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 25.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 20.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Basic Package",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            color: "red",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Remove Tar, Tree Sap, Bug Splatter etc.
                          </strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Machine Wax Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Window & Windshield Clean From Outside
                          </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <strong>Please Note:</strong> This is Hand Wash, Wax Buff
                      With Machine and Tire/Rim Clean Shine (Not Cut and
                      Polish).
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Basic Package: Hand Wash, Rim and Wheel Cleaning, Wax Buff, Tire Dressing, Door Jambs clean, Window and Windshield Clean.",
                price: 150.0, // Set the price accordingly
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Interior Vacuum & Wipe Down",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2022/03/When-should-you-detail-your-car-boost-mobile-detailing.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Clay Bar",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/clay-bar.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 85.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>,
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>,
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      //Commercial Vehicles
      {
        id: 4,
        staffDisplayName: "Commercial",
        type: "commercialVehicle",
        isAvailable: true,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719277924/Commercial_Truck-removebg-preview_ulo3fz.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "Semi - Sleeper",
            type: "semiSleeper",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/semi-sleeper-e1724914832780.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailng & Exterior Wash",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, Refrigerator</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Detailng & Exterior Wash",
                price: 280.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior Detailng",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Trunk/Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, Refrigerator</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailng",
                price: 200.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 110.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 1,
            categoryDisplayName: "Semi - Day Van",
            type: "semiDayCab",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/Semi-Day-Cab.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailng & Exterior Wash",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Detailng & Exterior Wash",
                price: 230.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior Detailng",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailng",
                price: 150.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 100.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            categoryDisplayName: "Dump Truck Van",
            type: "dumpTruckCab",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/Dump-Truck-Cab-.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailng & Exterior Wash",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Detailng & Exterior Wash",
                price: 400.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior Detailng",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailng",
                price: 180.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 250.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            categoryDisplayName: "Sprinter Van",
            type: "sprinterVanCab",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/Sprinter-Van-Cab-2.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailng & Exterior Wash",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Detailng & Exterior Wash",
                price: 225.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior Detailng",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailng",
                price: 135.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 100.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 4,
            categoryDisplayName: "Commercial Van",
            type: "commercialVanCab",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/Commercial-Van-Cab.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailng & Exterior Wash",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Detailng & Exterior Wash",
                price: 225.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior Detailng",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailng",
                price: 135.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Bay Wash & Polish</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 100.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 5,
            categoryDisplayName: "Big Machines/Equipment",
            type: "bigMachines",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/BIG-MACHINE-EQUIPMENT.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailng & Exterior Wash",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Exterior High Pressure Wash using PH Balanced Soaps
                          </strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Spot Paint Decontamination</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Remove Grease, Oil, heavy dirt etc.</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Detailng & Exterior Wash",
                price: 480.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior Detailng",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage area, Carpet, Floor Mats, Seats</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seat</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailng",
                price: 180.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>
                            Exterior High Pressure Wash using PH Balanced Soaps
                          </strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Spot Paint Decontamination</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Remove Grease, Oil, heavy dirt etc.</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Engine Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          <strong>
                            
                          </strong>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 320.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      // Rv Motors
      {
        id: 5,
        staffDisplayName: "RVs Motorhomes",
        type: "rv",
        isAvailable: true,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719277995/RV_motorhomes-removebg-preview_y7xulx.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "Class A Motorhome",
            type: "classA",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/RV-class-A-1.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior & Full Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Entire Rv, Storage area, Carpet, Floor Mats, Seats
                            etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean Vents</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior & Full Exterior",
                price: 25.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior & Light Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Entire Rv, Storage area, Carpet, Floor Mats, Seats
                            etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean Vents</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior & Light Exterior",
                price: 20.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Full Interior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Entire Rv, Storage area, Carpet, Floor Mats, Seats
                            etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean Vents</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior",
                price: 16.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 3,
                serviceDisplayName: "Full Exterior",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Exterior",
                price: 14.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 4,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 7.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 5,
                serviceDisplayName:
                  "Paint Correction (Oxidation & Swirl Marks)",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                        color: "red",
                      }}
                    >
                      <strong>Offer Ceramic Coating:</strong> Add on $12 Per Ft,
                      (Resistant to Swirl Marks)
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Paint Correction (Oxidation & Swirl Marks)",
                price: 40.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 1,
            categoryDisplayName: "Class B (Campervans)",
            type: "classB",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/RV-class-B-1.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior & Full Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Entire Rv, Storage area, Carpet, Floor Mats, Seats
                            etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean Vents</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior & Full Exterior",
                price: 25.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior & Light Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Entire Rv, Storage area, Carpet, Floor Mats, Seats
                            etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean Vents</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior & Light Exterior",
                price: 20.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Full Interior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Entire Rv, Storage area, Carpet, Floor Mats, Seats
                            etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean Vents</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior",
                price: 16.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 3,
                serviceDisplayName: "Full Exterior",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Exterior",
                price: 14.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 4,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 7.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 5,
                serviceDisplayName:
                  "Paint Correction (Oxidation & Swirl Marks)",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                        color: "red",
                      }}
                    >
                      <strong>Offer Ceramic Coating:</strong> Add on $12 Per Ft,
                      (Resistant to Swirl Marks)
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Paint Correction (Oxidation & Swirl Marks)",
                price: 40.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            categoryDisplayName: "Class C Motorhome",
            type: "classC",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/RV-class-C.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior & Full Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Entire Rv, Storage area, Carpet, Floor Mats, Seats
                            etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean Vents</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior & Full Exterior",
                price: 25.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior & Light Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Entire Rv, Storage area, Carpet, Floor Mats, Seats
                            etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean Vents</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior & Light Exterior",
                price: 20.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Full Interior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Entire Rv, Storage area, Carpet, Floor Mats, Seats
                            etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean Vents</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior",
                price: 16.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 3,
                serviceDisplayName: "Full Exterior",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Exterior",
                price: 14.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 4,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 7.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 5,
                serviceDisplayName:
                  "Paint Correction (Oxidation & Swirl Marks)",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                        color: "red",
                      }}
                    >
                      <strong>Offer Ceramic Coating:</strong> Add on $12 Per Ft,
                      (Resistant to Swirl Marks)
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Paint Correction (Oxidation & Swirl Marks)",
                price: 40.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            categoryDisplayName: "Travel Trailer",
            type: "truckTrailer",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/Travel-Trailer.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior & Full Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Entire Rv, Storage area, Carpet, Floor Mats, Seats
                            etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean Vents</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior & Full Exterior",
                price: 25.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior & Light Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Entire Rv, Storage area, Carpet, Floor Mats, Seats
                            etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean Vents</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior & Light Exterior",
                price: 20.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Full Interior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Entire Rv, Storage area, Carpet, Floor Mats, Seats
                            etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean Vents</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior",
                price: 16.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 3,
                serviceDisplayName: "Full Exterior",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Exterior",
                price: 14.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 4,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 7.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 5,
                serviceDisplayName:
                  "Paint Correction (Oxidation & Swirl Marks)",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                        color: "red",
                      }}
                    >
                      <strong>Offer Ceramic Coating:</strong> Add on $12 Per Ft,
                      (Resistant to Swirl Marks)
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Paint Correction (Oxidation & Swirl Marks)",
                price: 40.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
          {
            id: 4,
            categoryDisplayName: "Truck Camper",
            type: "truckCamper",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/08/Truck-Camper.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior & Full Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Entire Rv, Storage area, Carpet, Floor Mats, Seats
                            etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean Vents</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior & Full Exterior",
                price: 25.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior & Light Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Entire Rv, Storage area, Carpet, Floor Mats, Seats
                            etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean Vents</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior & Light Exterior",
                price: 20.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName: "Full Interior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Entire Rv, Storage area, Carpet, Floor Mats, Seats
                            etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator.</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean Vents</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Pet Hair Removal</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior",
                price: 16.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 3,
                serviceDisplayName: "Full Exterior",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Exterior",
                price: 14.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 4,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 7.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 5,
                serviceDisplayName:
                  "Paint Correction (Oxidation & Swirl Marks)",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Hand Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Roof Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Bug Removal</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wheel Clean & Tire Shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean vent cover</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Headlight</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks </strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        marginTop: "1rem",
                        color: "red",
                      }}
                    >
                      <strong>Offer Ceramic Coating:</strong> Add on $12 Per Ft,
                      (Resistant to Swirl Marks)
                    </p>
                  </>
                ),
                serviceShortDiscription:
                  "Paint Correction (Oxidation & Swirl Marks)",
                price: 40.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 180.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 100.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Scotch Guard Protection",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/scotch.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 80.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 55.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 7,
                    serviceDisplayName: "Headlight Restoration",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/child-safety-seat-back-car-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      // Fire Truck
      {
        id: 6,
        staffDisplayName: "Fire Truck",
        type: "fireTruck",
        isAvailable: true,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719278070/White_Fire_Truck-removebg-preview_hausrv.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "Fire Truck Service",
            type: "truckServices",
            servCategoryImgUrl: "",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Fire Truck",
                serviceDiscription: <></>,
                serviceShortDiscription: "Fire Truck Work",
                price: 180.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
            ],
          },
        ],
      },

      // Passenger Bus
      {
        id: 7,
        staffDisplayName: "Passenger Bus",
        type: "passengerBus",
        isAvailable: true,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719278127/bus-removebg-preview-1_woaqq8.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "All Bus Services",
            type: "busServices",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/09/volvo-bus.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailing and Full Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seats etc.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seats, etc.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Exterior Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Tire/rim clean shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks.</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Detailing and Full Exterior",
                price: 18.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 1,
                serviceDisplayName:
                  "Steam Interior Detailing and Exterior Wash",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seats etc.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seats, etc.</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Exterior Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Tire/rim clean shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks.</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Steam Interior Detailing and Exterior Wash",
                price: 8.5,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 2,
                serviceDisplayName: "Full Exterior",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Exterior Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Tire/rim clean shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks.</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Exterior",
                price: 10.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 3,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Exterior Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Tire/rim clean shine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks.</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 3.5,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 4,
                serviceDisplayName: "Steam Interior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seats etc.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seats, etc.</span>
                          <span>{Cross}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Steam Interior Detailing",
                price: 7.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
              {
                id: 5,
                serviceDisplayName: "Full Interior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seats etc.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Carpet, Floor Mats, Seats, etc.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors.
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments.</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailing",
                price: 9.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
            ],
          },
        ],
      },

      // Boats
      {
        id: 8,
        staffDisplayName: "Boats",
        type: "boat",
        isAvailable: true,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719278179/Yacht-removebg-preview_jbjuml.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "All Boats Services",
            type: "boatServices",
            servCategoryImgUrl:
              "https://boostmobiledetailing.ca/wp-content/uploads/2024/09/vecteezy_yacht-yacht-transparent-background-yachting-luxurious-boat_27232222.png",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "Full Interior Detailing AND Full Exterior",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Storage area, Carpet, Floor Mats, Seats etc
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Exterior Hull Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Entire Boat Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wet Sanding</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks.</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Detailing AND Full Exterior",
                price: 26.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 1,
                serviceDisplayName: "Full Interior Detailing AND Exterior Wash",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Storage area, Carpet, Floor Mats, Seats etc
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Exterior Hull Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Entire Boat Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wet Sanding</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks.</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Full Interior Detailing AND Exterior Wash",
                price: 20.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 2,
                serviceDisplayName:
                  "Paint Correction (Oxidation & Swirl Marks)",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Exterior Hull Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Entire Boat Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wet Sanding</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks.</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription:
                  "Paint Correction (Oxidation & Swirl Marks)",
                price: 40.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 3,
                serviceDisplayName: "Full Exterior",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Exterior Hull Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Entire Boat Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wet Sanding</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks.</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Exterior",
                price: 14.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 4,
                serviceDisplayName: "Exterior Wash",
                serviceDiscription: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>EXTERIOR</strong>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Exterior Hull Wash</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Entire Boat Wash & Wipe Down</strong>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wax/buff with machine</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Wet Sanding</strong>
                          <span>{Cross}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                            color: "red",
                          }}
                        >
                          <strong>Oxidation/Swirl Marks.</strong>
                          <span>{Cross}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Exterior Wash",
                price: 8.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
              {
                id: 5,
                serviceDisplayName: "Full Interior Detailing",
                serviceDiscription: (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <strong>INTERIOR</strong>
                      </div>

                      <div>
                        <strong>Deep Vacuum:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Storage area, Carpet, Floor Mats, Seats etc
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Shampoo:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Carpet, Floor Mats, Seats, furniture sofa etc.
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Steam and Brush:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Entire kitchen and Bathroom</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • Dash and Center Console, Cup Holders, All Doors
                          </span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Storage compartments, refrigerator</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Roof</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Protection:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>
                            • All Hard Interior Surfaces, Leather Conditioning
                          </span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <strong>Windows and Windshield:</strong>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Inside</span>
                          <span>{Checked}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <span>• Outside</span>
                          <span>{Checked}</span>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px dashed",
                            alignItems: "flex-end",
                          }}
                        >
                          <strong>Clean the bed/sleeping area</strong>
                          <span>{Checked}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ),
                serviceShortDiscription: "Full Interior Detailing",
                price: 14.0,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [
                  {
                    id: 0,
                    serviceDisplayName: "Mold / Virus Removal + Ozone",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/photo_2024-07-04_12-09-09-2.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 120.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 1,
                    serviceDisplayName: "Pet Hair Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/889230769_max.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 60.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 2,
                    serviceDisplayName: "Cigg / Pot Smell",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/pexels-ron-lach-9530544c-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 3,
                    serviceDisplayName: "Odor Removal",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/04/Ozone-Generator.webp",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 40.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 4,
                    serviceDisplayName: "Engine Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2021/06/IMG_0637-scaled.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 5,
                    serviceDisplayName: "Undercarriage Wash",
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/614b7f84f0b9d9b5822d9769.jpg",
                    serviceDiscription: <></>, // Add description here if available
                    serviceShortDiscription: "",
                    price: 35.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                  {
                    id: 6,
                    serviceDisplayName: "Headlight Restoration",
                    serviceDiscription: <></>, // Add description here if available
                    imageUrl:
                      "https://boostmobiledetailing.ca/wp-content/uploads/2024/07/IMG_7158-scaled.jpg",
                    serviceShortDiscription: "",
                    price: 50.0,
                    oldPrice: 0,
                    ribbon: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      // Helicopter
      {
        id: 9,
        staffDisplayName: "Helicopter",
        type: "helicopter",
        isAvailable: true,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719278231/White_Helicopter1-removebg_u3ffwu.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "Type of Plane?",
            type: "planeServices",
            servCategoryImgUrl: "",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "AirShip",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 0,
                oldPrice: 0,
                ribbon: "",
                imageUrl:
                  "https://boostmobiledetailing.ca/wp-content/uploads/2024/05/Airship-removebg-preview.png",
                addonService: [],
              },
              {
                id: 1,
                serviceDisplayName: "Hot Air Balloon",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 0,
                oldPrice: 0,
                ribbon: "",
                imageUrl:
                  "https://boostmobiledetailing.ca/wp-content/uploads/2024/05/hot_air_balloon-removebg-preview.png",
                addonService: [],
              },
              {
                id: 2,
                serviceDisplayName: "Glider",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 0,
                oldPrice: 0,
                ribbon: "",
                imageUrl:
                  "https://boostmobiledetailing.ca/wp-content/uploads/2024/05/glider-removebg-preview.png",
                addonService: [],
              },
              {
                id: 3,
                serviceDisplayName: "Helicopter",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 0,
                oldPrice: 0,
                ribbon: "",
                imageUrl:
                  "https://boostmobiledetailing.ca/wp-content/uploads/2024/05/helicopter-removebg-preview.png",
                addonService: [],
              },
              {
                id: 4,
                serviceDisplayName: "Gyroplane",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 0,
                oldPrice: 0,
                ribbon: "",
                imageUrl:
                  "https://boostmobiledetailing.ca/wp-content/uploads/2024/05/gyroplane-removebg-preview.png",
                addonService: [],
              },
              {
                id: 5,
                serviceDisplayName: "Tilrotor",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 0,
                oldPrice: 0,
                ribbon: "",
                imageUrl:
                  "https://boostmobiledetailing.ca/wp-content/uploads/2024/05/Tiltrotor-removebg-preview.png",
                addonService: [],
              },
              {
                id: 6,
                serviceDisplayName: "MonoPlane",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 0,
                oldPrice: 0,
                ribbon: "",
                imageUrl:
                  "https://boostmobiledetailing.ca/wp-content/uploads/2024/05/MonoPlane-removebg-preview.png",
                addonService: [],
              },
              {
                id: 7,
                serviceDisplayName: "BiPlane",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 0,
                oldPrice: 0,
                ribbon: "",
                imageUrl:
                  "https://boostmobiledetailing.ca/wp-content/uploads/2024/05/Biplane-removebg-preview.png",
                addonService: [],
              },
              {
                id: 8,
                serviceDisplayName: "SeaPlane",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 0,
                oldPrice: 0,
                ribbon: "",
                imageUrl:
                  "https://boostmobiledetailing.ca/wp-content/uploads/2024/05/seaplane-removebg-preview.png",
                addonService: [],
              },
              {
                id: 9,
                serviceDisplayName: "Private Jet",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 0,
                oldPrice: 0,
                ribbon: "",
                imageUrl:
                  "https://boostmobiledetailing.ca/wp-content/uploads/2024/05/PrivateJet-removebg-preview.png",
                addonService: [],
              },
              {
                id: 10,
                serviceDisplayName: "Passenger Plane",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 0,
                oldPrice: 0,
                ribbon: "",
                imageUrl:
                  "https://res.cloudinary.com/dsprifizw/image/upload/v1719277567/Passenger-removebg-preview_lzdrwt.png",
                addonService: [],
              },
              {
                id: 11,
                serviceDisplayName: "Cargo Plane",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 0,
                oldPrice: 0,
                ribbon: "",
                imageUrl:
                  "https://boostmobiledetailing.ca/wp-content/uploads/2024/05/CargoPlane-removebg-preview.png",
                addonService: [],
              },
              {
                id: 12,
                serviceDisplayName: "Military Jet",
                serviceDiscription: <></>,
                serviceShortDiscription: "",
                price: 0,
                oldPrice: 0,
                ribbon: "",
                imageUrl:
                  "https://boostmobiledetailing.ca/wp-content/uploads/2024/05/MilitaryJet-removebg-preview.png",
                addonService: [],
              },
            ],
          },
        ],
      },

      // House
      {
        id: 10,
        staffDisplayName: "House",
        type: "house",
        isAvailable: true,
        staffImgUrl:
          "https://res.cloudinary.com/dsprifizw/image/upload/v1719278286/White_Small_House-removebg-preview_atpbam.webP",
        descritpion: "",
        staffDiscount: 0,
        servicesList: [
          {
            id: 0,
            categoryDisplayName: "House Wash Booking?",
            type: "houseServices",
            servCategoryImgUrl: "",
            ribbon: "",
            description: "",
            isAvailable: true,
            services: [
              {
                id: 0,
                serviceDisplayName: "House Wash",
                serviceDiscription: <></>,
                serviceShortDiscription: "House Wash",
                price: 0.5,
                oldPrice: 0,
                ribbon: "",
                imageUrl: "",
                addonService: [],
              },
            ],
          },
        ],
      },
    ],
  },
]
