import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { openToastify } from "../../store/actions/toastifyAction"
import { REDUX_TOASTIFY_DEFAULTS } from "../../store/config"

const Tostify = () => {
  // const userData = useSelector((state) => state.userInfoReducer)
  const dispatch = useDispatch()
  const toastifyState = useSelector((state) => state.toastifyReducer)
  const autoCloseTime = 3000 //ms

  const defaultToast = (e) => {
    toast(e, {
      position: "top-right",
      autoClose: autoCloseTime,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  }
  const successToast = (e) => {
    toast.success(e, {
      position: "top-right",
      autoClose: autoCloseTime,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  }
  const warningToast = (e) => {
    toast.warn(e, {
      position: "top-right",
      autoClose: autoCloseTime,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  }
  const infoToast = (e) => {
    toast.warn(e, {
      position: "top-right",
      autoClose: autoCloseTime,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  }
  const errorToast = (e) => {
    toast.error(e, {
      position: "top-right",
      autoClose: autoCloseTime,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  }

  const noToast = () => {
    setTimeout(() => {
      //// console.log("toast");
      dispatch(openToastify("There is no message", "type_of_toastify"))
    }, autoCloseTime + 1000)
  }
  //redux for toastify
  let count = 0
  const checkToastType = () => {
    if (
      toastifyState.toastifyId ===
        REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS() &&
      count === 1
    ) {
      successToast(toastifyState.toastifyPayload)
    }
    if (
      toastifyState.toastifyId ===
        REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN() &&
      count === 1
    ) {
      warningToast(toastifyState.toastifyPayload)
    }
    if (
      toastifyState.toastifyId ===
        REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_INFO() &&
      count === 1
    ) {
      infoToast(toastifyState.toastifyPayload)
    }
    if (
      toastifyState.toastifyId ===
        REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR() &&
      count === 1
    ) {
      errorToast(toastifyState.toastifyPayload)
    }
    if (
      toastifyState.toastifyId ===
        REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_DEFAULT() &&
      count === 1
    ) {
      defaultToast(toastifyState.toastifyPayload)
    } else {
      noToast()
    }
  }

  useEffect(() => {
    count++
    checkToastType()
    //// console.log(toastifyState)
  }, [toastifyState.toastifyId])

  return (
    <>
      <ToastContainer />
    </>
  )
}

export default Tostify

// use
// import Tostify from "../../../Notification/Tostify"
// import { openToastify } from "../../../../../core/Redux/action/toastifyAction"
// dispatch(openToastify("message", "type_of_toastify"))
// return (<><Tostify /></>)
