import React, { useEffect, useState } from "react"
import logo from "../../asserts/boost-detailing-logo.png"
import { useDispatch, useSelector } from "react-redux"
import { bookingAction } from "../../store/actions/bookingAction"
import { REDUX_STORE_DEFAULTS } from "../../store/config"
import { AppointmentData } from "../../config/bookingDataConfig"

function SelectLocation() {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState()
  let { totalSteps, stepNo, location } = useSelector(
    (state) => state.bookingReducer
  )

  const submitHandler = (e) => {
    setSelected(e)
    // console.log("qq", e)

    const locationData = {
      id: e.id,
      description: e.description,
      displayName: e.displayName,
      locationDiscount: e.locationDiscount,
      locationRole: e.locationRole,
    }

    if (location.locationRole === e.locationRole) {
      dispatch(
        bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_LOCATION(), "")
      )
    } else {
      dispatch(
        bookingAction(
          REDUX_STORE_DEFAULTS.UPDATE_BOOKING_LOCATION(),
          locationData
        )
      )
      // setTimeout(() => {
      //   dispatch(
      //     bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STEPS(), stepNo + 1)
      //   )
      // }, 100)
    }

    // dispatch(
    //   bookingAction(
    //     REDUX_STORE_DEFAULTS.UPDATE_DISCOUNT(),
    //     locationData.locationDiscount
    //   )
    // )
  }

  return (
    <>
      {/* location */}
      <div className="space-y-4 w-full">
        {/* <h1>Select Service Type</h1> */}
        <div className="space-y-4 sm:space-y-0 lg:space-x-4 flex flex-col lg:flex-row justify-center sm:justify-start ">
          {AppointmentData.map((value, key) => {
            return (
              <div
                key={key}
                className={` relative overflow-hidden cursor-pointer h-[12rem] sm:w-[11rem] flex flex-col items-center justify-center rounded-xl backdrop-blur-xl ${
                  selected === value.locationRole ||
                  location.locationRole === value.locationRole
                    ? "bg-yellow-medium_dark bg-opacity -35 shadow shadow-white"
                    : "border-2 border-yellow-light_medium"
                } `}
                onClick={() => {
                  submitHandler(value)
                }}
              >
                <img
                  src={value.imgUrl}
                  alt="location"
                  className="object-cover w-20"
                  loading="lazy"
                />
                <span className="capitalize mt-2">{value.displayName}</span>
                <span className="capitalize text-xs text-slate-600 opacity-80">
                  {value.description}
                </span>

                <div className="absolute left-0 top-0 bg-yellow-light text-white px-2 uppercase flex items-center rounded-br-xl">
                  <span>{value.ribbon}</span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default SelectLocation
