import React, { useState } from "react"
import {
  createMobileBooking,
  createShopBooking,
} from "../../services/api/apiModule"
import { useDispatch, useSelector } from "react-redux"
import { bookingAction } from "../../store/actions/bookingAction"
import {
  REDUX_STORE_DEFAULTS,
  REDUX_TOASTIFY_DEFAULTS,
} from "../../store/config"
import { useEffect } from "react"
import { openToastify } from "../../store/actions/toastifyAction"
import CryptoJS from "crypto-js"
import { updateData } from "../../store/actions/multipleBookingAction"
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  doc,
} from "firebase/firestore"
import { db } from "../../../firebase-config"

const Confirmation = () => {
  const dispatch = useDispatch()
  let {
    bookingStepsLabel,
    stepNo,
    location,
    staffCategory,
    service,
    addOnService,
    bookingSlot,
    userInfo,
    otherUserInfo,
    otherVehicleInfo,
    confirmBooking,
    discount,
  } = useSelector((state) => state.bookingReducer)

  let { allBookingData } = useSelector((state) => state.multipleBookingReducer)
  const [isLoading, setIsLoading] = useState(false)
  const [isBooked, setIsBooked] = useState(false)
  const [isBookingId, setIsBookingId] = useState("")

  //Add Users
  const usersCollectionRef = collection(db, "booked-clients")
  const saveDataFireBase = async (newBookingId) => {
    const values = {
      firstName: userInfo.name ?? "",
      phoneNumber: userInfo.phoneNumber ?? "",
      email: userInfo.email ?? "",
      address: userInfo.address ?? "",
      createdAt: new Date().toISOString() ?? "",
      bookingInfo: [
        { bookingId: newBookingId, createdAt: new Date().toISOString() },
      ], // Initialize bookingId as an array
    }
    // console.log(values, "saveDataFireBase")

    try {
      // Create a query to check if the email already exists
      const q = query(usersCollectionRef, where("email", "==", values.email))
      const querySnapshot = await getDocs(q)

      if (querySnapshot.empty) {
        // If no document is found with the same email, add a new document
        await addDoc(usersCollectionRef, { ...values })
        // console.log("New user added successfully.")
      } else {
        // Iterate over each document found with the same email
        querySnapshot.forEach(async (docSnapshot) => {
          const userDocRef = doc(db, "booked-clients", docSnapshot.id)

          // Fetch the current document data to check the address
          const userDocData = docSnapshot.data()

          // Check if the address is empty or undefined
          const currentAddress = userDocData.address ?? ""
          const updatedFields = {}

          // Update the address only if it is currently empty
          if (!currentAddress.trim()) {
            updatedFields.address = values.address
          }

          // Always update bookingInfo by adding new booking entry
          updatedFields.bookingInfo = arrayUnion({
            bookingId: newBookingId,
            createdAt: new Date().toISOString(),
          })

          // Perform the update with conditional fields
          await updateDoc(userDocRef, updatedFields)
        })
      }
    } catch (error) {
      console.error("Error checking or adding document: ", error)
    }
  }

  const generateHash = (s) => {
    // Hash the string using SHA-256
    const hash = CryptoJS.SHA256(s).toString(CryptoJS.enc.Hex)

    // Convert the first 8 characters of the hash to an integer
    const largeNumber = parseInt(hash.substring(0, 8), 16)

    // Map the large number to a 4-digit number
    const fourDigitHash = largeNumber % 10000

    return fourDigitHash.toString().padStart(4, "0") // Ensure it's always 4 digits
  }

  const close = () => {
    dispatch(
      bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_CONFIRMATION(), false)
    )

    // dispatch(bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STEPS(), 1))
    setIsBooked(false)
    dispatch(
      dispatch(bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STAFF(), {}))
    )
    dispatch(updateData([], 1))
    dispatch(
      bookingAction(REDUX_STORE_DEFAULTS.UPDATE_MULTIPLE_BOOKING(), false)
    )
  }

  const transformShopBookingData = (allBookingData) => {
    return allBookingData.map((item) => ({
      service: {
        vehicleType: item.vehicle.type,
        serviceName: item.service.categoryType,
        serviceId: item.service.serviceId,
      },
      additionalServiceList: item.addOnService.map(
        (addOn) => addOn.addOnServiceId
      ),
    }))
  }

  const handlePlaceShopBooking = async () => {
    try {
      const params = {
        firstName: userInfo.name ?? "",
        lastName: "",
        phoneNumber: userInfo.phoneNumber ?? "",
        email: userInfo.email ?? "",
        bookingDate: bookingSlot.date ?? "",
        bookingSlot: bookingSlot.id + 1 ?? 0,
        booking: transformShopBookingData(allBookingData) ?? [],
        giftCardCode: discount?.name?.length > 20 ? discount.name : "",
        couponName: discount?.name?.length < 20 ? discount.name : "",
      }
      const response = await createShopBooking(params)
      // console.log(response)
      if (response.success) {
        dispatch(
          openToastify(
            "Slot Booked Successfully",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
          )
        )
        setIsBooked(true)
        setIsBookingId(generateHash(response.msg._id))
        saveDataFireBase(response.msg._id)
      }
    } catch (error) {
      // console.log(error.response.data.msg)
      dispatch(
        openToastify(
          error.response.data.msg,
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
        )
      )
      setTimeout(() => {
        close()
      }, 600)
    }
  }

  const transformMobileBookingData = (allBookingData) => {
    return allBookingData.map((item) => ({
      service: {
        vehicleType: item.vehicle.type,
        serviceName: item.service.categoryType,
        serviceId: item.service.serviceId,
      },
      additionalServiceList: item.addOnService.map(
        (addOn) => addOn.addOnServiceId
      ),
      images: item.images ?? "",
      pricingType: item.pricingType ?? "",
      totalLength: item.totalLength ?? 0,
    }))
  }

  const handlePlaceMobileBooking = async () => {
    try {
      const params = {
        firstName: userInfo.name ?? "",
        lastName: "",
        phoneNumber: userInfo.phoneNumber ?? "",
        email: userInfo.email ?? "",
        bookingDate: bookingSlot.date ?? "",
        bookingSlot: bookingSlot.id + 1 ?? 0,
        location: userInfo.address ?? "",
        booking: transformMobileBookingData(allBookingData) ?? [],
        isWaterAvailable: otherUserInfo.waterSupply === "yes" ? true : false,
        isElectrictyAvailable:
          otherUserInfo.powerSupply === "yes" ? true : false,
        giftCardCode: discount?.name?.length > 20 ? discount.name : "",
        couponName: discount?.name?.length < 20 ? discount.name : "",
      }

      const response = await createMobileBooking(params)
      // console.log(response, params)
      if (response.success) {
        dispatch(
          openToastify(
            "Slot Booked Successfully",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
          )
        )
        setIsBooked(true)
        setIsBookingId(generateHash(response.msg._id))
        saveDataFireBase(response.msg._id)
      }
    } catch (error) {
      // console.log(error?.response?.data?.msg)
      dispatch(
        openToastify(
          error?.response?.data?.msg,
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
        )
      )
      setTimeout(() => {
        close()
      }, 600)
    }
  }

  useEffect(() => {
    // console.log(otherUserInfo, otherVehicleInfo)
    // console.log("confirmBooking",confirmBooking);
    if (!confirmBooking) return

    if (location.locationRole === "MOBILE") {
      handlePlaceMobileBooking()
    } else if (location.locationRole === "SHOP") {
      handlePlaceShopBooking()
    } else {
      return
    }
  }, [confirmBooking])

  const newBooking = () => {
    dispatch(bookingAction(REDUX_STORE_DEFAULTS.UPDATE_BOOKING_STEPS(), 1))
    close()
  }
  return (
    <>
      {confirmBooking ? (
        <>
          <div
            className={` ${
              isBooked ? "" : "hidden"
            } fixed inset-0 bg-black z-10 bg-opacity-30 backdrop-blur-sm flex justify-center items-center `}
          >
            <div className="bg-white lg:h-[70%] h-[80%] lg:w-[80%] w-[90%] border">
              <div className="bg-red- 700 flex justify-end -mt-4 -mr-2">
                <div
                  className="bg-white h-8 w-8 rounded-full flex justify-center items-center border border-yellow-light cursor-pointer"
                  onClick={() => {
                    close()
                  }}
                >
                  <svg
                    className="size-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>
              <div className="p-5 flex items-center justify-center h-full flex-col lg:space-y-8 space-y-4 text-center">
                <div className="flex flex-col items-center font-medium lg:text-4xl lg:space-y-4 space-y-2">
                  <svg
                    className="lg:size-20 size-8"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>

                  <span>Thank you for choosing Boost Mobile Detailing!</span>
                  <span>ID #{isBookingId}</span>
                </div>
                <p className="text-xs">
                  We will get in touch with you shortly to confirm/schedule the
                  Time and Day for the appointment. In case we do not contact
                  you, please feel free to CALL us or send TEXT message
                  (236-992-8610). Your confirmation number:
                </p>
                <div className="lg:space-x-5 text-sm space-y-2 lg:space-y-0 flex flex-wrap justify-center">
                  <a
                    className="bg-yellow-medium_dark rounded-md py-2 px-10 font-medium"
                    href="https://boostmobiledetailing.ca/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Boost Detailing
                  </a>
                  {/* <button className="bg-yellow-medium_dark rounded-md py-2 px-10 font-medium">
                    Add To Calender
                  </button> */}
                  <button
                    className="bg-yellow-medium_dark rounded-md py-2 px-10 font-medium"
                    onClick={() => {
                      newBooking()
                    }}
                  >
                    Create New Booking
                  </button>
                  <a
                    className="bg-yellow-medium_dark rounded-md py-2 px-10 font-medium"
                    href="https://boostmobiledetailing.ca/ceramic-coating/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Boost Ceramic
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* {!isLoading ? (
            <>
              <div className="fixed inset-0 bg-black z-10 bg-opacity-30 backdrop-blur-sm flex justify-center items-center"></div>
            </>
          ) : (
            <></>
          )} */}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default Confirmation
